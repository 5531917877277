import React, { useEffect } from 'react';
import '../styles/components/Possibility.css';
import { modifyScene } from '../redux/actions/actionCreators';
import { connect } from 'react-redux';
import { Pose } from './';
import { setPossibilityChoice } from '../redux/actions/choiceActions';
import { updateHauteurChoice } from '../redux/actions/actionCreators';

const Possibility = (props) => {
  useEffect(() => {
    let elements = document.getElementsByClassName('p-radio-0');
    for (let i = 0; i < elements.length; i++) {
      elements[i].checked = true;
    }
  }, [props.navigation]);

  function handleClick(type_modification_code, possibility, index) {
    if (possibility.poses !== undefined) {
      props.dispatch(
        modifyScene(
          props.navigation,
          props.scene,
          props.choice,
          type_modification_code,
          possibility.code,
          props.type_modification.possibilities[index].poses[
            props.type_modification.default_pose
          ].code,
          props.element_modif_code,
          props.type_modification.possibilities[index].poses[
            props.type_modification.default_pose
          ].img_scene_path
        )
      );
    } else {
      props.dispatch(
        modifyScene(
          props.navigation,
          props.scene,
          props.choice,
          type_modification_code,
          possibility.code,
          null,
          props.element_modif_code,
          possibility.img_scene_path
        )
      );
    }
  }

  function handleClickAvecSans(type_modification_code, possibility, index) {
    let elements = document.getElementsByClassName('p-radio');
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('p-radio-0');
    }
    elements = document.getElementsByClassName('p-radio-subcontainer');
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].id.includes(type_modification_code)) {
        elements[i].classList.remove('p-radio-subcontainer-active');
      }
    }
    elements = document.getElementsByClassName('p-radio');
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].name.includes(type_modification_code)) {
        elements[i].checked = false;
      }
    }
    elements = document.getElementsByClassName('p-radio-label');
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].id.includes(type_modification_code)) {
        elements[i].classList.remove('p-radio-label-active');
      }
    }

    document
      .getElementById(
        'p-radio-subcontainer-' +
          type_modification_code +
          '-' +
          possibility.code
      )
      .classList.add('p-radio-subcontainer-active');
    document.getElementById(
      'p-radio-' + type_modification_code + '-' + possibility.code
    ).checked = true;
    document
      .getElementById(
        'p-radio-label-' + type_modification_code + '-' + possibility.code
      )
      .classList.add('p-radio-label-active');
    if (possibility.poses !== undefined) {
      props.dispatch(
        modifyScene(
          props.navigation,
          props.scene,
          props.choice,
          type_modification_code,
          possibility.code,
          props.type_modification.possibilities[index].poses[
            props.type_modification.default_pose
          ].code,
          props.element_modif_code,
          props.type_modification.possibilities[index].poses[
            props.type_modification.default_pose
          ].img_scene_path
        )
      );
    } else {
      props.dispatch(
        modifyScene(
          props.navigation,
          props.scene,
          props.choice,
          type_modification_code,
          possibility.code,
          null,
          props.element_modif_code,
          possibility.img_scene_path
        )
      );
    }
  }

  if (props.choice !== null) {
    if (
      props.type_modification.code.includes('avecsans') ||
      props.type_modification.code === 'hauteur'
    ) {
      return (
        <div className='p-possibilities-avecsans'>
          <div className='p-possibilities-avecsans-radio-container'>
            {props.type_modification.possibilities.map((possibility, index) => {
              if (
                props.type_modification.code === 'hauteur' &&
                !possibility.code.includes(
                  props.choice.classifications_scenes[0].scenes[0]
                    .elements_modifies[0].types_modifications.possibilities.code
                )
              ) {
                return <div key={index}></div>;
              } else {
                return (
                  <div
                    key={index}
                    id={
                      'p-radio-subcontainer-' +
                      props.type_modification.code +
                      '-' +
                      possibility.code
                    }
                    className={
                      props.choice.classifications_scenes[
                        props.usefulIndexes.classification_scene_index
                      ].scenes[props.usefulIndexes.scene_index]
                        .elements_modifies[props.element_modif_index]
                        .types_modifications.possibilities.code ===
                      possibility.code
                        ? 'p-radio-subcontainer p-radio-subcontainer-active p-radio-margin-right'
                        : 'p-radio-subcontainer p-radio-margin-right'
                    }
                    onClick={() =>
                      handleClickAvecSans(
                        props.type_modification.code,
                        possibility,
                        index
                      )
                    }
                  >
                    <input
                      id={
                        'p-radio-' +
                        props.type_modification.code +
                        '-' +
                        possibility.code
                      }
                      className={
                        props.choice.classifications_scenes[
                          props.usefulIndexes.classification_scene_index
                        ].scenes[props.usefulIndexes.scene_index]
                          .elements_modifies[props.element_modif_index]
                          .types_modifications.possibilities.code ===
                        possibility.code
                          ? 'p-radio p-radio-0'
                          : 'p-radio'
                      }
                      type='radio'
                      name={'pdv-type' + props.type_modification.code}
                      value={possibility.name}
                    />
                    <label
                      id={
                        'p-radio-label-' +
                        props.type_modification.code +
                        '-' +
                        possibility.code
                      }
                      className={
                        props.choice.classifications_scenes[
                          props.usefulIndexes.classification_scene_index
                        ].scenes[props.usefulIndexes.scene_index]
                          .elements_modifies[props.element_modif_index]
                          .types_modifications.possibilities.code ===
                        possibility.code
                          ? 'p-radio-label p-radio-label-active'
                          : 'p-radio-label'
                      }
                      htmlFor={possibility.name}
                    >
                      {possibility.name}
                    </label>
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div className='p-possibilities'>
          <h3 className='p-h3'>{props.type_modification.name}</h3>
          {props.type_modification.possibilities.map((possibility, index) => {
            return (
              <div key={index} className='p-option-container'>
                <div
                  className={
                    props.choice.classifications_scenes[
                      props.usefulIndexes.classification_scene_index
                    ].scenes[props.usefulIndexes.scene_index].elements_modifies[
                      props.element_modif_index
                    ].types_modifications.possibilities.code ===
                    possibility.code
                      ? 'p-option p-option-active'
                      : 'p-option p-option-inactive'
                  }
                  onClick={() =>
                    handleClick(
                      props.type_modification.code,
                      possibility,
                      index
                    )
                  }
                >
                  <p className='p-option-txt'>{possibility.name}</p>
                  <div
                    className='p-option-img'
                    style={{
                      backgroundImage:
                        'url(' +
                        require('../assets/images/' +
                          possibility.img_miniature_path) +
                        ')',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                    }}
                  ></div>
                </div>
                <Pose
                  type_modification_code={props.type_modification.code}
                  element_modif_code={props.element_modif_code}
                  element_modif_index={props.element_modif_index}
                  possibility={possibility}
                ></Pose>
              </div>
            );
          })}
        </div>
      );
    }
  } else {
    return <div></div>;
  }
};

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    scene: state.scene,
    choice: state.choice,
    usefulIndexes: state.usefulIndexes,
  };
}

export default connect(mapStateToProps)(Possibility);
