import { INIT_SCENE, UPDATE_SCENE } from './types';

// ACTION CREATORS

function initializeSceneData(mainIndex, subIndex, configuration) {
  mainIndex === 1 ? (subIndex = 1) : (subIndex = subIndex);
  var currentScene =
    configuration.classifications_scenes[mainIndex].scenes[subIndex];
  var img_root_path = currentScene.img_root_path;
  var possibilities_rendered = [];

  currentScene.elements_modifies.forEach((element) => {
    possibilities_rendered.push({
      code: element.types_modifications[0].code,
      element_modif_code: element.code,
      img_path: element.types_modifications[0].possibilities[0].img_scene_path,
    });
  });

  return {
    img_root_path: img_root_path,
    possibilities_rendered: possibilities_rendered,
  };
}

export function initScene(scene) {
  return {
    type: INIT_SCENE,
    payload: scene,
  };
}

export function updateScene(data) {
  return {
    type: UPDATE_SCENE,
    payload: data,
  };
}
