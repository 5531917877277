import { SET_UP_CONFIGURATION } from '../actions/types';
import { UPDATE_CONFIGURATION } from '../actions/types';
const initialState = null;

export function configurationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_UP_CONFIGURATION:
      let classifications_scenes;
      action.payload.data.map((d) => {
        if (d.vocation === action.payload.vocation) {
          classifications_scenes = d.classification_scenes;
        }
      });
      return Object.assign({}, state, {
        classifications_scenes: classifications_scenes,
      });
    case UPDATE_CONFIGURATION:
      return Object.assign({}, state, {
        classifications_scenes: action.payload,
      });
    default:
      return state;
  }
}
