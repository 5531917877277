import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './fonts/INTERMARCHE/INTERMARCHE-Bold.otf';
import './fonts/OpenSans/OpenSans-Bold.ttf';
import './fonts/OpenSans/OpenSans-SemiBold.ttf';
import { Provider } from 'react-redux';
import store from './redux/store';

document.documentElement.style.setProperty('--theme-color-red', '#ff1e24');
document.documentElement.style.setProperty(
  '--theme-color-extremelylightgrey',
  '#f6f6f6'
);
document.documentElement.style.setProperty(
  '--theme-color-lightgrey',
  '#d8d8d8'
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
