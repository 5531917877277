import React from 'react';
import '../../styles/components/MainNav.css';
import { connect } from 'react-redux';
import { goToClassificationScene } from '../../redux/actions/actionCreators';
import { Link } from 'react-router-dom';

const SyntheseMainNav = (props) => {
  return (
    <div className='mn-container'>
      <div className='mn-navbar'>
        <Link to='/accueil' className='mn-a mn-a-previous'>
          ACCUEIL
        </Link>
        <div className='mn-vl' />
        <Link
          to={{
            pathname: '/formulaire',
            search:
              props.projects[props.usefulIndexes.project_index].project_name,
          }}
          className='mn-a mn-a-previous'>
          FORMULAIRE
        </Link>
        <div className='mn-vl' />
        {props.configuration.classifications_scenes.map(
          (classification_scene, index) => {
            return (
              <div key={index} className='mn-a-container'>
                <Link
                  to={{ pathname: '/projet', from: 'synthese' }}
                  className='mn-a mn-a-previous'
                  onClick={() =>
                    props.dispatch(
                      goToClassificationScene(
                        props.configuration,
                        props.choice,
                        classification_scene.code,
                        index
                      )
                    )
                  }>
                  {classification_scene.name}
                </Link>
                <div className='mn-vl' />
              </div>
            );
          }
        )}
        <a className='mn-a mn-a-active'>SYNTHÈSE</a>
        <div className='mn-vl' />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    configuration: state.configuration,
    choice: state.choice,
    projects: state.user.info.projects,
    usefulIndexes: state.usefulIndexes,
  };
}

export default connect(mapStateToProps)(SyntheseMainNav);
