import React, { useEffect, useState } from 'react';
import '../styles/components/ShowScene.css';
import { connect } from 'react-redux';
import { FullScreen } from './index';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';
import mergeImages from 'merge-images';

const ShowScene = (props) => {
  const [newMergedImage, setNewMergedImage] = useState();

  useEffect(() => {
    function mergeSelectedImages() {
      let selectedImages = [];
      selectedImages.push(
        require('../assets/images/' + props.images.img_root_path)
      );
      props.images.possibilities_rendered.map((possibility_rendered) => {
        if (
          possibility_rendered.img_path !== undefined &&
          !possibility_rendered.element_modif_code.includes('enseigne') &&
          !possibility_rendered.element_modif_code.includes('bergerie')
        ) {
          selectedImages.push(
            require('../assets/images/' + possibility_rendered.img_path)
          );
        }
      });
      props.images.possibilities_rendered.map((possibility_rendered) => {
        if (
          possibility_rendered.img_path !== undefined &&
          (possibility_rendered.element_modif_code.includes('enseigne') ||
            possibility_rendered.element_modif_code.includes('bergerie'))
        ) {
          selectedImages.push(
            require('../assets/images/' + possibility_rendered.img_path)
          );
        }
      });
      mergeImages(selectedImages).then((b64) => setNewMergedImage(b64));
    }

    mergeSelectedImages();
  }, [props.images]);

  return (
    <div
      className='ss-container'
      style={{ width: props.width, height: props.height }}
    >
      <div
        style={{
          position: 'absolute',
          width: props.width,
          height: props.height,
        }}
      >
        <FullScreen
          trigger={
            <img
              alt=''
              className='ss-icon'
              src={require('../assets/icons/fullscreen.png')}
            ></img>
          }
          images={props.images}
        />
      </div>
      {newMergedImage !== undefined ? (
        <InnerImageZoom
          src={newMergedImage}
          width={props.width}
          height={props.height}
          zoomScale={1.5}
          hideHint={true}
        ></InnerImageZoom>
      ) : (
        <div></div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    images: state.scene,
  };
}

export default connect(mapStateToProps)(ShowScene);
