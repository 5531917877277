import React,{useState} from 'react';
import '../styles/pages/Login.css';
import logo from '../assets/images/Logo.png';
import { Link } from 'react-router-dom';
import {resetpassword} from '../redux/actions/adminAction'

import {connect} from 'react-redux';
import {AuthModal} from '../components/auth';

const ResetPassword = (props) => {
    const [confirmpassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [modalShow, setModalShow] = React.useState(false);
    const query = new URLSearchParams(props.location.search);
    const token = query.get('token')

    const signInWithEmailAndPasswordHandler =  (event,email, password) => {
            event.preventDefault();

            if(confirmpassword!=password){
                setError("Vérifier votre saisie : les mots de passe ne correspondent pas ")
                setModalShow(true);
            }

            else{
                props.dispatch(resetpassword({token:token,password:password})).then((data)=>{
                    console.log("DATA",data)

                    if(data.nModified!=1){
                        setModalShow(true);
                        setError("Une erreur est survenue veuillez contacter l'adminitrateur")
                    }
                    else{
                        //props.dispatch(loadUserInfo(data))
                        props.history.push('/connexion');
                    }
                })
            }



/*
         */

       // const reponse=await  props.dispatch(signin({email:email,password:password}));
        //console.log("Reponse ",props.loggeduser)
        }


    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;

        if(name === 'userConfirmPassword') {
            setConfirmPassword(value);
        }
        else if(name === 'userPassword'){
            setPassword(value);
        }
    };

  return (
    <div className='login-container'>
      <div className='login-header-container'>
        <img
          className='login-header-logo'
          src={logo}
          width='101'
          height='120'
        />
        <div>
          <h1 className='login-header-title'>
            MON APPLI <br />
            <span>MISE EN PROJET</span> <br />
            FAB'MAG
          </h1>
        </div>
      </div>
      <div className='login-subcontainer'>
        <p className='login-title'>Modification mot de passe </p>
        <label htmlFor='utilisateur' className='login-label'>
          Mot de passe :
        </label>
        <input
          className='login-input'
          type='password'
          name="userPassword"
          value = {password}
          placeholder="Mot de passe"
          id="userPassword"
          onChange = {(event) => onChangeHandler(event)}
        />
        <label htmlFor='motdepasse' className='login-label'>
         Confirmation:
        </label>
        <input
          className='login-input'
          type='password'
          name="userConfirmPassword"
          value = {confirmpassword}
          placeholder="Confirmer votre mot de passe"
          id="userConfirmPassword"
          onChange = {(event) => onChangeHandler(event)}
        />
        <button onClick = {(event) => {signInWithEmailAndPasswordHandler(event, confirmpassword, password)}} className='login-button' id='login-button'>
          <span>VALIDER</span>
        </button>
      </div>
        <AuthModal  show={modalShow}
                    onHide={() => setModalShow(false)}
                    error={error}/>
    </div>

  );
};

function mapStateToProps(state) {
    console.log(state)
    return {
        loggeduser: state.admin.loggeduser

    };
}

export default connect(mapStateToProps)(ResetPassword);
