/**
 * Created by mnassih on 06/10/2020.
 */
import { FETCH_ALL_USER, CREATE_USER, LOGGED_USER } from '../actions/types';
import axios from 'axios';

const userToken = localStorage.getItem('usertoken');
const instance = axios.create({
  headers: {
    Authorization: `Bearer ${userToken}`,
  },
});

export function fetchallusers(users) {
  return {
    type: FETCH_ALL_USER,
    payload: users,
  };
}

export const fetchAllUsers = () => {
  return (dispatch) => {
    let url = process.env.REACT_APP_BO_API_HOST + '/api/v1/users/';

    return instance
      .get(url)
      .then((response) => {
        console.log(response.data);
        dispatch(fetchallusers(response.data));
      })
      .catch((err) => {
        console.log(err);
        // dispatch(requestCanalFailed(err));
      });
  };
};

export function createUser(user) {
  return {
    type: CREATE_USER,
    payload: user,
  };
}

export const createuser = (user) => {
  return (dispatch) => {
    let url = process.env.REACT_APP_BO_API_HOST + '/api/auth/signup';
    return new Promise((resolve, reject) => {
      instance
        .post(url, user)
        .then((response) => {
          console.log(response.data);
          dispatch(createUser(response.data));
          return resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
          // dispatch(requestCanalFailed(err));
        });
    });
  };
};

export const removeuser = (userid) => {
  return (dispatch) => {
    let url = process.env.REACT_APP_BO_API_HOST + '/api/v1/users/' + userid;
    return new Promise((resolve, reject) => {
      instance
        .delete(url)
        .then((response) => {
          console.log(response.data);
          dispatch(createUser(response.data));
          return resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
          // dispatch(requestCanalFailed(err));
        });
    });
  };
};

export function signinUser(user) {
  return {
    type: LOGGED_USER,
    payload: user,
  };
}

export const signin = (userdata) => {
  console.log("signin()",userdata)
  return (dispatch) => {
    let url = process.env.REACT_APP_BO_API_HOST + '/api/v1/users/sigin';
    return new Promise((resolve, reject) => {
      instance
        .post(url, userdata)
        .then((response) => {
          console.log('USER test :', response);
          dispatch(signinUser(response.data));
          resolve({ ...response.data, status: 200 });
        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log("An error occured ",error.response.data);
            dispatch(signinUser({ status: error.response.data }));
            resolve({
              status: error.response.status,
              message: error.response.data.message,
            });
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    });
  };
};

export const updateuser = (user) => {
  return (dispatch) => {
    let url = process.env.REACT_APP_BO_API_HOST + '/api/v1/users/';
    return new Promise((resolve, reject) => {
      instance
        .put(url, user)
        .then((response) => {
          console.log(response.data);
          dispatch(createUser(response.data));
          return resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
          // dispatch(requestCanalFailed(err));
        });
    });
  };
};

export const resetpassword = (user) => {
  return (dispatch) => {
    let url =
      process.env.REACT_APP_BO_API_HOST + '/api/v1/users/updatepassword';
    return new Promise((resolve, reject) => {
      instance
        .post(url, user)
        .then((response) => {
          console.log(response.data);
          // dispatch(createUser(response.data))
          return resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
          // dispatch(requestCanalFailed(err));
        });
    });
  };
};

export const requestinitpassword = (email) => {
  return (dispatch) => {
    let url = process.env.REACT_APP_BO_API_HOST + '/api/v1/mail';
    return new Promise((resolve, reject) => {
      instance
        .post(url, email)
        .then((response) => {
          console.log(response.data);
          // dispatch(createUser(response.data))
          return resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
          // dispatch(requestCanalFailed(err));
        });
    });
  };
};
