import {
  ADD_PROJECT,
  MODIFY_PROJECT_INFO,
  MODIFY_PROJECT_CHOICE,
  UPDATE_PROJECT,
  REMOVE_PROJECT,
  LOAD_USER_INFO,
} from './types';

import axios from 'axios';
const userToken = localStorage.getItem('usertoken');
const url = process.env.REACT_APP_BO_API_HOST + '/api/v1/projects/';
const instance = axios.create({
  headers: {
    Authorization: `Bearer ${userToken}`,
  },
});

function initializeAddedProject(
  name,
  info,
  configuration,
  dateCreated,
  pdv,
  region
) {
  let project_name = name;
  let project_info = info;
  let classifications_scenes = [];
  configuration.classifications_scenes.forEach(
    (classification_scene, index1) => {
      let code = classification_scene.code;
      let scenes = [];
      classification_scene.scenes.forEach((scene, index2) => {
        let code = scene.code;
        let elements_modifies = [];
        scene.elements_modifies.forEach((element_modifie, index3) => {
          if (
            element_modifie.types_modifications[0].possibilities[0].poses !==
            undefined
          ) {
            elements_modifies.push({
              code: element_modifie.code,
              types_modifications: {
                code: element_modifie.types_modifications[0].code,
                possibilities: {
                  code:
                    element_modifie.types_modifications[0].possibilities[0]
                      .code,
                  poses: {
                    code:
                      element_modifie.types_modifications[0].possibilities[0]
                        .poses[
                        element_modifie.types_modifications[0].default_pose
                      ].code,
                  },
                },
              },
            });
          } else {
            if (index1 === 0 && element_modifie.code === 'les_murs') {
              elements_modifies.push({
                code: element_modifie.code,
                types_modifications: {
                  code: element_modifie.types_modifications[0].code,
                  possibilities: {
                    code:
                      element_modifie.types_modifications[0].possibilities[0]
                        .code,
                  },
                },
              });
            } else if (
              element_modifie.types_modifications[0].code.includes('avec')
            ) {
              elements_modifies.push({
                code: element_modifie.code,
                types_modifications: {
                  code: element_modifie.types_modifications[0].code,
                  possibilities: {
                    code:
                      element_modifie.types_modifications[0].possibilities[
                        element_modifie.types_modifications[0]
                          .default_possibility
                      ].code,
                  },
                },
              });
            } else {
              elements_modifies.push({
                code: element_modifie.code,
                types_modifications: {
                  code: element_modifie.types_modifications[0].code,
                  possibilities: {
                    code:
                      element_modifie.types_modifications[0].possibilities[0]
                        .code,
                  },
                },
              });
            }
          }
        });
        scenes.push({
          code: code,
          elements_modifies: elements_modifies,
        });
      });
      classifications_scenes.push({ code: code, scenes: scenes });
    }
  );

  const newProject = {
    project_name: project_name,
    region: region,
    pdv: pdv,
    dateCreated: dateCreated,
    disabledScenesIndexes: [],
    project_info: project_info,
    classifications_scenes: classifications_scenes,
  };

  instance.post(url, { ...newProject, userToken: userToken });

  return newProject;
}

function initializeModifiedProject(configuration) {
  let classifications_scenes = [];
  configuration.classifications_scenes.forEach(
    (classification_scene, index1) => {
      let code = classification_scene.code;
      let scenes = [];
      classification_scene.scenes.forEach((scene, index2) => {
        let code = scene.code;
        let elements_modifies = [];
        scene.elements_modifies.forEach((element_modifie, index3) => {
          if (
            element_modifie.types_modifications[0].possibilities[0].poses !==
            undefined
          ) {
            elements_modifies.push({
              code: element_modifie.code,
              types_modifications: {
                code: element_modifie.types_modifications[0].code,
                possibilities: {
                  code:
                    element_modifie.types_modifications[0].possibilities[0]
                      .code,
                  poses: {
                    code:
                      element_modifie.types_modifications[0].possibilities[0]
                        .poses[
                        element_modifie.types_modifications[0].default_pose
                      ].code,
                  },
                },
              },
            });
          } else {
            if (index1 === 0 && element_modifie.code === 'les_murs') {
              elements_modifies.push({
                code: element_modifie.code,
                types_modifications: {
                  code: element_modifie.types_modifications[0].code,
                  possibilities: {
                    code:
                      element_modifie.types_modifications[0].possibilities[0]
                        .code,
                  },
                },
              });
            } else if (
              element_modifie.types_modifications[0].code.includes('avec')
            ) {
              elements_modifies.push({
                code: element_modifie.code,
                types_modifications: {
                  code: element_modifie.types_modifications[0].code,
                  possibilities: {
                    code:
                      element_modifie.types_modifications[0].possibilities[
                        element_modifie.types_modifications[0]
                          .default_possibility
                      ].code,
                  },
                },
              });
            } else {
              elements_modifies.push({
                code: element_modifie.code,
                types_modifications: {
                  code: element_modifie.types_modifications[0].code,
                  possibilities: {
                    code:
                      element_modifie.types_modifications[0].possibilities[0]
                        .code,
                  },
                },
              });
            }
          }
        });
        scenes.push({
          code: code,
          elements_modifies: elements_modifies,
        });
      });
      classifications_scenes.push({ code: code, scenes: scenes });
    }
  );

  instance.post(url, { ...classifications_scenes, userToken: userToken });

  return classifications_scenes;
}

export function saveProject(choice, projects) {
  return (dispatch) => {
    let idx;
    projects.map((project, index) => {
      if (project.project_name === choice.project_name) {
        projects[index] = choice;
        idx = index;
        instance
          .post(url, { ...choice, userToken: userToken })
          .then((response) => {
            dispatch(
              updateProject({ projects: projects, idx: idx, success: true })
            );
          })
          .catch((err) => {
            dispatch(
              updateProject({ projects: projects, idx: idx, success: false })
            );
          });
      }
    });
  };
}

// ACTION CREATORS

export function addProject(
  name,
  info,
  configuration,
  dateCreated,
  pdv,
  region
) {
  return {
    type: ADD_PROJECT,
    payload: initializeAddedProject(
      name,
      info,
      configuration,
      dateCreated,
      pdv,
      region
    ),
  };
}

export function modifyProjectChoice(
  initialName,
  name,
  pdv,
  info,
  configuration
) {
  return {
    type: MODIFY_PROJECT_CHOICE,
    payload: {
      initialName: initialName,
      name: name,
      pdv: pdv,
      info: info,
      classifications_scenes: initializeModifiedProject(configuration),
    },
  };
}

export function modifyProjectInfo(initialName, name, pdv, info) {
  return {
    type: MODIFY_PROJECT_INFO,
    payload: { initialName: initialName, name: name, pdv: pdv, info: info },
  };
}

export function updateProject(data) {
  return {
    type: UPDATE_PROJECT,
    payload: data,
  };
}

export function removeProject(data) {
  return {
    type: REMOVE_PROJECT,
    payload: data,
  };
}

export function loadUserInfo(data) {
  return {
    type: LOAD_USER_INFO,
    payload: data,
  };
}
