import React, { useState } from 'react';
import '../styles/pages/Login.css';
import logo from '../assets/images/Logo.png';
import { Link } from 'react-router-dom';
import { signin, requestinitpassword } from '../redux/actions/adminAction';
import { loadUserInfo } from '../redux/actions/userActions';
import { connect } from 'react-redux';
import { AuthModal } from '../components/auth';

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [initemail, setInitEmail] = useState('');

  const [error, setError] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = React.useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();

    props
      .dispatch(signin({ email: email, password: password }))
      .then((data) => {
        if (data.status != 200) {
          setModalShow(true);
          setError(data.message);
          setModalTitle('UNE ERREUR EST SURVENUE');
        } else {
          props.dispatch(loadUserInfo(data));
          props.history.push('/accueil');
        }
      });

    // const reponse=await  props.dispatch(signin({email:email,password:password}));
    //console.log("Reponse ",props.loggeduser)
  };

  const signInWithEmailAndPasswordHandlerWithkey = (event, email, password) => {
    if (event.keyCode === 13) {
      props
        .dispatch(signin({ email: email, password: password }))
        .then((data) => {
          if (data.status != 200) {
            setModalShow(true);
            setError(data.message);
            setModalTitle('UNE ERREUR EST SURVENUE');
          } else {
            props.dispatch(loadUserInfo(data));
            props.history.push('/accueil');
          }
        });

      // const reponse=await  props.dispatch(signin({email:email,password:password}));
      //console.log("Reponse ",props.loggeduser)
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === 'userEmail') {
      setEmail(value);
    }
    if (name === 'inituserEmail') {
      setInitEmail(value);
    } else if (name === 'userPassword') {
      setPassword(value);
    }
  };
  const showpasswordinit = () => {
    setForgotPassword(true);
  };
  const requestInitpassword = () => {
    if (initemail == '') {
      setModalShow(true);
      setError("L'email est obligatoire");
      setModalTitle('UNE ERREUR EST SURVENUE');
      return;
    }
    props.dispatch(requestinitpassword({ email: initemail })).then((data) => {
      setModalShow(true);
      setModalTitle('CONFIRMATION');
      setError(data.message);
    });
  };

  const gotohome = () => {
    setForgotPassword(false);
  };

  const loginform = () => {
    return !forgotPassword ? (
      <div className='login-subcontainer'>
        <p className='login-title'>CONNEXION</p>
        <label htmlFor='utilisateur' className='login-label'>
          Login :
        </label>
        <input
          className='login-input'
          type='email'
          name='userEmail'
          value={email}
          placeholder='Email'
          id='userEmail'
          onChange={(event) => onChangeHandler(event)}
          onKeyDown={(event) => {
            signInWithEmailAndPasswordHandlerWithkey(event, email, password);
          }}
        />
        <label htmlFor='motdepasse' className='login-label'>
          Mot de passe :
        </label>
        <input
          className='login-input'
          type='password'
          name='userPassword'
          value={password}
          placeholder='Votre mot de passe'
          id='userPassword'
          onChange={(event) => onChangeHandler(event)}
          onKeyDown={(event) => {
            signInWithEmailAndPasswordHandlerWithkey(event, email, password);
          }}
        />

        <button
          onClick={(event) => {
            signInWithEmailAndPasswordHandler(event, email, password);
          }}
          className='login-button'
          id='login-button'
        >
          <span>VALIDER</span>
        </button>
        <div className='passwordreminder'>
          <a onClick={showpasswordinit}>Mot de passe oublié ?</a>
        </div>
      </div>
    ) : (
      <div className='login-subcontainer'>
        <p className='login-title'>REINITIALISER VOTRE MOT DE PASSE</p>
        <label htmlFor='utilisateur' className='login-label'>
          Veuillez saisir votre email
        </label>
        <input
          className='login-input'
          type='email'
          name='inituserEmail'
          value={initemail}
          placeholder='Email'
          id='inituserEmail'
          onChange={(event) => onChangeHandler(event)}
        />
        <button
          onClick={(event) => {
            requestInitpassword();
          }}
          className='login-button'
          id='login-button'
        >
          <span>VALIDER</span>
        </button>
      </div>
    );
  };

  return (
    <div className='login-container'>
      <div className='login-header-container' onClick={gotohome}>
        <img
          className='login-header-logo'
          src={logo}
          width='101'
          height='120'
        />
        <div>
          <h1 className='login-header-title'>
            MON APPLI <br />
            <span>MISE EN PROJET</span> <br />
            FAB'MAG
          </h1>
        </div>
      </div>
      {loginform()}
      <AuthModal
        show={modalShow}
        title={modalTitle}
        onHide={() => setModalShow(false)}
        error={error}
      />
    </div>
  );
};

function mapStateToProps(state) {
  console.log(state);
  return {
    loggeduser: state.admin.loggeduser,
  };
}

export default connect(mapStateToProps)(Login);
