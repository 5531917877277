import React from 'react';
import '../styles/components/TypeModification.css';
import Possibility from './Possibility';

const TypeModification = (props) => {
  return (
    <div className='tm-container'>
      <h2 className='tm-h2'>{props.elements.name}</h2>
      {props.elements.types_modifications.map((type_modification, index) => {
        return (
          <Possibility
            key={index}
            element_modif_index={props.element_modif_index}
            element_modif_code={props.elements.code}
            type_modification={type_modification}
          />
        );
      })}
    </div>
  );
};

export default TypeModification;
