import {
  SET_POSSIBILITY_CHOICE,
  SET_TOTAL_CHOICE,
  ADD_DISABLED_SCENE_INDEX,
  REMOVE_DISABLED_SCENE_INDEX,
} from '../actions/types';

const initialState = {
  project_name: null,
  disabledScenesIndexes: [],
  project_info: null,
  classifications_scenes: null,
};

export function choiceReducer(state = initialState, action) {
  switch (action.type) {
    case SET_POSSIBILITY_CHOICE:
      return Object.assign({}, state, {
        ...state,
        project_name: action.payload.project_name,
        project_info: action.payload.project_info,
        classifications_scenes: action.payload.classifications_scenes,
      });
    case SET_TOTAL_CHOICE:
      return Object.assign({}, state, {
        project_name: action.payload.project_name,
        dateCreated: action.payload.dateCreated,
        disabledScenesIndexes: action.payload.disabledScenesIndexes,
        project_info: action.payload.project_info,
        classifications_scenes: action.payload.classifications_scenes,
      });
    case ADD_DISABLED_SCENE_INDEX:
      return Object.assign({}, state, {
        ...state,
        disabledScenesIndexes: [...state.disabledScenesIndexes, action.payload],
      });
    case REMOVE_DISABLED_SCENE_INDEX:
      let removedIndexes = [...state.disabledScenesIndexes];
      removedIndexes.splice(removedIndexes.indexOf(action.payload), 1);
      return Object.assign({}, state, {
        ...state,
        disabledScenesIndexes: removedIndexes,
      });
    default:
      return state;
  }
}
