export const SET_UP_CONFIGURATION = 'SET_UP_CONFIGURATION';
export const UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION';
export const SET_POSSIBILITY_CHOICE = 'SET_POSSIBILITY_CHOICE';
export const SET_TOTAL_CHOICE = 'SET_TOTAL_CHOICE';
export const INIT_SCENE = 'INIT_SCENE';
export const UPDATE_SCENE = 'UPDATE_SCENE';
export const ADD_PROJECT = 'ADD_PROJECT';
export const MODIFY_PROJECT_INFO = 'MODIFY_PROJECT_INFO';
export const MODIFY_PROJECT_CHOICE = 'MODIFY_PROJECT_CHOICE';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const LOAD_USER_INFO = 'LOAD_USER_INFO';
export const NAVIGATE = 'NAVIGATE';

export const SET_CURRENT_PROJECT_INDEX = 'SET_CURRENT_PROJECT_INDEX';
export const SET_CLASSIFICATION_SCENE_INDEX = 'SET_CLASSIFICATION_SCENE_INDEX';
export const SET_SCENE_INDEX = 'SET_SCENE_INDEX';

/** USER ADMIN CONSTANT **/

export const FETCH_ALL_USER = 'FETCH_ALL_USER';
export const CREATE_USER = 'CREATE_USER';
export const LOGGED_USER = 'LOGGED_USER';

export const ADD_DISABLED_SCENE_INDEX = 'ADD_DISABLED_SCENE_INDEX';
export const REMOVE_DISABLED_SCENE_INDEX = 'REMOVE_DISABLED_SCENE_INDEX';
