import React from 'react';
import Popup from 'reactjs-popup';
import '../styles/components/FullScreen.css';

const FullScreen = (props) => {
  return (
    <Popup
      trigger={props.trigger}
      modal
      contentStyle={{
        width: '100%',
        height: '100%',
        border: 'none',
        padding: 0,
      }}
    >
      {(close) => (
        <div>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <img
              onClick={close}
              className='fs-icon'
              src={require('../assets/icons/Fermer.png')}
            ></img>
          </div>
          <img
            className='fs-img'
            src={require('../assets/images/' + props.images.img_root_path)}
            width={'100%'}
            height={'100%'}
          ></img>
          {props.images.possibilities_rendered.map((possibility_rendered) => {
            if (
              possibility_rendered.img_path !== undefined &&
              !possibility_rendered.element_modif_code.includes('enseigne') &&
              !possibility_rendered.element_modif_code.includes('bergerie')
            ) {
              return (
                <img
                  className='fs-img'
                  src={require('../assets/images/' +
                    possibility_rendered.img_path)}
                  width={'100%'}
                  height={'100%'}
                ></img>
              );
            }
          })}
          {props.images.possibilities_rendered.map((possibility_rendered) => {
            if (
              possibility_rendered.img_path !== undefined &&
              (possibility_rendered.element_modif_code.includes('enseigne') ||
                possibility_rendered.element_modif_code.includes('bergerie'))
            ) {
              return (
                <img
                  className='fs-img'
                  src={require('../assets/images/' +
                    possibility_rendered.img_path)}
                  width={'100%'}
                  height={'100%'}
                ></img>
              );
            }
          })}
        </div>
      )}
    </Popup>
  );
};

export default FullScreen;
