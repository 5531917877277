import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import '../styles/components/SavePopup.css';

const SavePopup = (props) => {
  const [msg, setMsg] = useState();

  useEffect(() => {
    props.whereFrom === undefined
      ? props.success
        ? setMsg('Votre projet a été enregistré')
        : setMsg("L'enregistrement du projet a echoué")
      : setMsg('Votre projet a été modifié');
  }, [props.success]);

  return (
    <Popup
      onClose={props.onClose}
      open={props.open}
      trigger={props.trigger}
      closeOnDocumentClick
      contentStyle={{
        maxWidth: 500,
        height: 75,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {(close) => (
        <div>
          <img
            alt=''
            onClick={close}
            className='sp-icon'
            width={15}
            height={15}
            src={require('../assets/icons/Fermer.png')}
          ></img>
          <p style={{ margin: 0, fontFamily: 'INTERMARCHE-Bold' }}>{msg}</p>
        </div>
      )}
    </Popup>
  );
};

export default SavePopup;
