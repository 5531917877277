import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/Logo.png';
import '../styles/pages/Synthese.css';
import { connect } from 'react-redux';
import { Possibility, SyntheseHeader } from '../components/';

const Synthese = (props) => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  let rayonComment1 =
    "--> l'image prend en compte les matériaux sélectionnés pour le rayon concerné et non ceux des rayons périphériques";
  let rayonComment2 =
    '--> cette mise en situation n’exclue pas une validation de votre projet et de votre plan au COVAL';

  useEffect(() => {
    updateImageDimensions();
    window.addEventListener('resize', updateImageDimensions);
  }, []);

  function printSynthese() {
    var css = '@page { size: landscape; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);

    window.print();
  }

  const imgBaseWidth = 1920;
  const imgBaseHeight = 1080;

  function updateImageDimensions() {
    let left = document.querySelector('.synthese-scene-images-container');

    if (left !== null) {
      //this calculates the padding %
      const w = left.clientWidth;
      const h = left.clientHeight;

      let imgWidth = w;
      //calculates hight based on aspect ratio
      let imgHeight = (imgWidth * imgBaseHeight) / imgBaseWidth;

      //if height is greater than the inner container, set the maximun size and recalculate width base on max-height
      if (imgHeight > h) {
        imgHeight = h;
        imgWidth = (imgHeight * imgBaseWidth) / imgBaseHeight;
      }

      setWidth(imgWidth);
      setHeight(imgHeight);
    }
  }

  let coque_images = [];
  let images = [];
  let possibilities_images = [];
  let images_to_render = [];

  return (
    <div className='synthese-container'>
      <div className='dont-show'>
        <SyntheseHeader location={props.location} history={props.history} />
      </div>
      <div className='print-page-break synthese-selections-container'>
        <div className='synthese-projectinfo-selection'>
          <p className='synthese-title'>
            {
              props.projects[props.currentProjectIndex].project_info
                .informationsGenerales.values.nomDuPointDeVente.value
            }
          </p>
          {Object.values(
            props.projects[props.currentProjectIndex].project_info
          ).map((infoCategory, index1) => {
            return (
              <div key={'infoCategory' + index1}>
                <p className='synthese-projectinfo-category'>
                  {infoCategory.name}
                </p>
                {Object.values(infoCategory.values).map((info, index2) => {
                  if (info.value !== null && typeof info.value === 'string') {
                    return (
                      <p
                        key={('infoCategory' + index1.toString()).concat(
                          index2.toString()
                        )}
                        className='synthese-projectinfo-info-container'
                      >
                        <span className='synthese-projectinfo-info-p1'>
                          {info.name}
                        </span>
                        <span>{' : '}</span>
                        <span
                          className={
                            info.name ===
                              'Vocation point de vente avant le projet' ||
                            info.name ===
                              'Vocation point de vente après le projet' ||
                            info.name === 'Composition de la place des saveurs'
                              ? 'synthese-projectinfo-info-p2 line-break'
                              : 'synthese-projectinfo-info-p2'
                          }
                        >
                          {info.value}
                        </span>
                      </p>
                    );
                  } else if (
                    info.value !== null &&
                    info.value.length > 0 &&
                    Array.isArray(info.value)
                  ) {
                    return (
                      <p
                        key={('infoCategory' + index1.toString()).concat(
                          index2.toString()
                        )}
                        className='synthese-projectinfo-info-container'
                      >
                        <span className='synthese-projectinfo-info-p1'>
                          {info.name}
                        </span>
                        <span>{' : '}</span>
                        {info.value.map((el) => {
                          return (
                            <span className='synthese-projectinfo-info-p2-v2'>
                              {'. '} {el}
                            </span>
                          );
                        })}
                      </p>
                    );
                  } else {
                    return (
                      <div
                        key={('infoCategory' + index1.toString()).concat(
                          index2.toString()
                        )}
                      ></div>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
        <div className='print-page-break synthese-selection'>
          {props.projects[props.currentProjectIndex].classifications_scenes.map(
            (classification_scene, index1) => {
              return (
                <div
                  key={'classification_scene' + index1}
                  className='synthese-classification-scene'
                >
                  {classification_scene.scenes.map((scene, index2) => {
                    if (
                      !props.choice.disabledScenesIndexes.includes(index2) ||
                      index1 === 0
                    ) {
                      images_to_render = [];
                      possibilities_images = [];
                      return (
                        <div
                          key={(
                            'classification_scene' + index1.toString()
                          ).concat(index2.toString())}
                          className='print-synthese-scene-container synthese-scene-container'
                        >
                          <div className='print-width synthese-scene'>
                            <p className='synthese-scene-p'>
                              {
                                props.configuration.classifications_scenes[
                                  index1
                                ].scenes[index2].name
                              }
                            </p>
                            {scene.elements_modifies.map(
                              (element_modifie, index3) => {
                                let type_modification_choisi;
                                let possibility_choisie;
                                let pose_choisie;
                                props.configuration.classifications_scenes[
                                  index1
                                ].scenes[index2].elements_modifies[
                                  index3
                                ].types_modifications.map(
                                  (type_modification) => {
                                    if (
                                      type_modification.code ===
                                      element_modifie.types_modifications.code
                                    ) {
                                      type_modification_choisi = type_modification;
                                      type_modification.possibilities.forEach(
                                        (possibility) => {
                                          if (
                                            possibility.code ===
                                            element_modifie.types_modifications
                                              .possibilities.code
                                          ) {
                                            if (index1 === 0) {
                                              images.push(possibility.code);
                                              coque_images.push(
                                                possibility.img_scene_path
                                              );
                                            }
                                            possibility_choisie = possibility;
                                            if (
                                              possibility.poses !== undefined
                                            ) {
                                              possibility.poses.forEach(
                                                (pose) => {
                                                  if (
                                                    pose.code ===
                                                    element_modifie
                                                      .types_modifications
                                                      .possibilities.poses.code
                                                  ) {
                                                    pose_choisie = pose;
                                                    possibilities_images.push(
                                                      pose.img_scene_path
                                                    );
                                                  }
                                                }
                                              );
                                            } else {
                                              possibilities_images.push(
                                                possibility.img_scene_path
                                              );
                                            }
                                          }
                                        }
                                      );
                                    }
                                  }
                                );
                                return (
                                  <div
                                    key={(
                                      'classification_scene' + index1.toString()
                                    )
                                      .concat(index2.toString())
                                      .concat(index3.toString())}
                                    className='synthese-element-modifie'
                                  >
                                    <div>
                                      <p className='synthese-element-modifie-type-modification-p'>
                                        {
                                          props.configuration
                                            .classifications_scenes[index1]
                                            .scenes[index2].elements_modifies[
                                            index3
                                          ].name
                                        }
                                        {'  >  '}
                                        {type_modification_choisi.name}
                                        {'  >'}
                                      </p>
                                    </div>
                                    <p className='synthese-possibilities-p'>
                                      {possibility_choisie.name}
                                    </p>
                                    {!possibility_choisie.name.includes(
                                      'AVEC'
                                    ) &&
                                    !possibility_choisie.name.includes(
                                      'SANS'
                                    ) ? (
                                      <img
                                        className='print-vignette-size'
                                        src={require('../assets/images/' +
                                          possibility_choisie.img_miniature_path)}
                                      ></img>
                                    ) : (
                                      <div></div>
                                    )}
                                    {pose_choisie !== undefined ? (
                                      <div>
                                        <div className='synthese-poses-container'>
                                          <p className='synthese-poses-title-p'>
                                            {'Type de pose >  '}
                                          </p>
                                          <p className='synthese-poses-p'>
                                            {pose_choisie.name}
                                          </p>
                                        </div>
                                        <img
                                          className='print-vignette-size'
                                          src={require('../assets/images/' +
                                            pose_choisie.img_miniature_path)}
                                        ></img>
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className='print-synthese-scene-images-container synthese-scene-images-container'>
                            {/* root image */}
                            <div className='ss-img'>
                              <img
                                className='print-ss-img'
                                src={require('../assets/images/' +
                                  props.configuration.classifications_scenes[
                                    index1
                                  ].scenes[index2].img_root_path)}
                                width={width}
                                height={height}
                              ></img>
                              <p
                                style={{ width: width }}
                                className='synthese-comment print-synthese-comment'
                              >
                                {rayonComment1}
                                <br></br>
                                {rayonComment2}
                              </p>
                              <div className='synthese-encadre print-synthese-encadre'></div>
                            </div>

                            {/* coque images */}
                            {coque_images.map((image, imgIndex) => {
                              if (index1 === 0) {
                                images_to_render.push(
                                  <img
                                    key={'coque' + imgIndex}
                                    className='ss-img print-ss-img'
                                    src={require('../assets/images/' + image)}
                                    width={width}
                                    height={height}
                                  ></img>
                                );
                              }
                            })}

                            {/* default images */}
                            {images.map((image, imgIndex) => {
                              if (
                                index1 > 0 &&
                                props.configuration.classifications_scenes[
                                  index1
                                ].scenes[index2].default_imgs[image] !==
                                  undefined
                              ) {
                                images_to_render.push(
                                  <img
                                    key={'default' + imgIndex}
                                    className='ss-img print-ss-img'
                                    src={require('../assets/images/' +
                                      props.configuration
                                        .classifications_scenes[index1].scenes[
                                        index2
                                      ].default_imgs[image])}
                                    width={width}
                                    height={height}
                                  ></img>
                                );
                              }
                            })}

                            {/* possibilities and poses images */}
                            {possibilities_images.map((image, imgIndex) => {
                              if (index1 > 0) {
                                images_to_render.push(
                                  <img
                                    key={'possibility' + imgIndex}
                                    className='ss-img print-ss-img'
                                    src={require('../assets/images/' + image)}
                                    width={width}
                                    height={height}
                                  ></img>
                                );
                              }
                            })}

                            {/* RENDER IMAGES */}
                            {images_to_render}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              );
            }
          )}
        </div>
        <button
          onClick={() => printSynthese()}
          className='dont-show synthese-print-btn'
        >
          IMPRESSION
        </button>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    configuration: state.configuration,
    projects: state.user.info.projects,
    choice: state.choice,
    currentProjectIndex: state.usefulIndexes.project_index,
    images: state.scene,
    usefulIndexes: state.usefulIndexes,
  };
}

export default connect(mapStateToProps)(Synthese);
