import React, { useState, useEffect } from 'react';
import FormulaireMainNav from './FormulaireMainNav';
import '../../styles/components/Header.css';
import logo from '../../assets/images/Logo.png';
import Select from 'react-select/';
import arrow from '../../assets/icons/Rectangle.png';
import { addProject, saveProject } from '../../redux/actions/userActions';
import { selectProject } from '../../redux/actions/actionCreators';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCurrentProjectIndex } from '../../redux/actions/usefulIndexesActions';
import logout from '../../assets/icons/logout.png';
import configuration from '../../script/output.json';
import configuration_safari from '../../script/safari/output.json';
import { setUpConfiguration } from '../../redux/actions/configurationActions';

const FormulaireHeader = (props) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    let options = [];
    props.projects.map((project, index) => {
      options.push({
        value: project.project_name,
        label: project.project_name,
      });
    });
    setOptions(options);

    setSelectedOption(props.usefulIndexes.project_index);
  }, [props.projects]);

  function handleChange(selectedOption) {
    setSelectedOption(selectedOption);
    let classifications_scenes;
    configuration.map((data) => {
      if (
        data.vocation ===
        props.projects[
          options.indexOf(selectedOption)
        ].project_info.informationsGenerales.values.typeDePointDeVente.value.toUpperCase()
      ) {
        // Detect Chrome
        let chromeAgent = navigator.userAgent.indexOf('Chrome') > -1;
        // Detect Safari
        let safariAgent = navigator.userAgent.indexOf('Safari') > -1;
        // Discard Safari since it also matches Chrome
        if (chromeAgent && safariAgent) safariAgent = false;
        if (!safariAgent) {
          props.dispatch(setUpConfiguration(configuration, data.vocation));
        } else {
          props.dispatch(
            setUpConfiguration(configuration_safari, data.vocation)
          );
        }
        classifications_scenes = data.classification_scenes;
      }
    });

    let config = { classifications_scenes: classifications_scenes };

    props.dispatch(setCurrentProjectIndex(options.indexOf(selectedOption)));
    if (selectedOption !== null) {
      props.dispatch(
        selectProject(config, props.projects, selectedOption.value)
      );
    }

    props.history.push({
      pathname: '/formulaire',
      search: selectedOption.value,
    });
  }

  function handleSave() {
    props.dispatch(saveProject(props.choice, props.projects));
  }

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      width: 325,
      height: 50,
      fontSize: 16,
      backgroundColor: 'rgba(0,0,0,0.08)',
      paddingLeft: 68,
      fontFamily: 'OpenSans-Bold',
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
      cursor: 'text',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      position: 'absolute',
      textAlign: 'center',
      right: 50,
      color: !state.isFocused ? 'transparent' : 'grey',
      '&:hover': {
        color: '#aaaaaa',
      },
    }),
    dropdownIndicator: () => ({
      color: 'transparent',
      marginLeft: 2,
      width: 50,
      height: 50,
      background: 'rgba(0,0,0,0.08) url(' + arrow + ') no-repeat center center',
      cursor: 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      paddingLeft: 68,
      backgroundColor: isFocused ? 'rgba(0,0,0,0.16)' : null,
      color: 'black',
      cursor: 'pointer',
    }),
  };

  const handleLogout = () => {
    localStorage.clear();
    props.history.push('/connexion');
  };

  return (
    <div className='header' style={{ marginBottom: 20 }}>
      <Link className='header-logo' to='/accueil'>
        <img className='header-logo-img' src={logo} width='101' height='120' />
      </Link>
      <div>
        <h1 className='header-title'>
          MON APPLI <br />
          <span>MISE EN PROJET</span> <br />
          FAB'MAG
        </h1>
      </div>
      <div className='header-part2'>
        <div className='header-part2-top'>
          <Select
            placeholder='Tapez pour chercher/créer un projet'
            styles={customStyles}
            value={options[selectedOption]}
            onChange={handleChange}
            options={options}
          />
          <button
            className='header-save-btn'
            onClick={handleSave}
            type='submit'>
            ENREGISTRER
          </button>
          <img className='logoutIcon' src={logout} onClick={handleLogout} />
        </div>
        <FormulaireMainNav />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    choice: state.choice,
    projects: state.user.info.projects,
    configuration: state.configuration,
    usefulIndexes: state.usefulIndexes,
  };
}

export default connect(mapStateToProps)(FormulaireHeader);
