/**
 * Created by mnassih on 06/10/2020.
 */
import { FETCH_ALL_USER, CREATE_USER, LOGGED_USER } from '../actions/types';

const initialState = {loggeduser:JSON.parse(localStorage.getItem('loggeduser'))};

export function adminReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_USER:
      return Object.assign({}, state, {
        users: action.payload,
      });
    case CREATE_USER:
      return Object.assign({}, state, {
        usercreated: action.payload,
      });
    case LOGGED_USER:
      console.log("action.payload",action)
      localStorage.setItem('loggeduser', JSON.stringify(action.payload));
      localStorage.setItem('usertoken', action.payload.token);
      return Object.assign({}, state, {
        loggeduser: action.payload,
      });
    default:
      return state;
  }
}
