import { NAVIGATE } from '../actions/types';
import navigation from '../tests/navigation.json';

const initialState = navigation;

export function navigationReducer(state = initialState, action) {
  switch (action.type) {
    case NAVIGATE:
      return Object.assign({}, state, {
        scene_current: action.payload,
      });
    default:
      return state;
  }
}
