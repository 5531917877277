import {
  SET_POSSIBILITY_CHOICE,
  SET_TOTAL_CHOICE,
  ADD_DISABLED_SCENE_INDEX,
  REMOVE_DISABLED_SCENE_INDEX,
} from './types';

function getProjectChoice(project_name, projects) {
  let project = projects.map((project) => {
    if (project.project_name === project_name) return project;
  });
  return project[0];
}

// ACTION CREATORS

export function setPossibilityChoice(data) {
  return {
    type: SET_POSSIBILITY_CHOICE,
    payload: data,
  };
}

export function setTotalChoice(project) {
  return {
    type: SET_TOTAL_CHOICE,
    payload: project,
  };
}

export function addDisabledSceneIndex(index) {
  return {
    type: ADD_DISABLED_SCENE_INDEX,
    payload: index,
  };
}

export function removeDisabledSceneIndex(index) {
  return {
    type: REMOVE_DISABLED_SCENE_INDEX,
    payload: index,
  };
}
