import React from "react";
import { Redirect,Route } from "react-router-dom";




const PrivateRoute = ({ component:Component, ...args }) => (

    <Route
        {...args}
        render={props=>

            localStorage.getItem("loggeduser")?(
                    <Component {...props} />
                ):
                <Redirect to="/connexion"/>
        }



    />
);

export default PrivateRoute;