import React, { useLayoutEffect, useEffect, useRef } from 'react';
import '../styles/components/SubMainNav.css';
import { connect } from 'react-redux';
import { goToScene } from '../redux/actions/actionCreators';

const SubMainNav = (props) => {
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      let firstFromagerIndex;
      props.configuration.classifications_scenes[1].scenes.map(
        (scene, index) => {
          if (scene.name === 'Le Fromager') {
            if (firstFromagerIndex === undefined) {
              firstFromagerIndex = index;
            }
            if (!props.disabledScenesIndexes.includes(index)) {
              if (
                document.getElementById('smn-p-' + firstFromagerIndex) !== null
              ) {
                document
                  .getElementById('smn-p-' + firstFromagerIndex)
                  .classList.remove('smn-p-disabled');
              }
            }
          }
        }
      );
      if (
        props.configuration.classifications_scenes[1].scenes[
          props.usefulIndexes.scene_index
        ].name === 'Le Fromager'
      ) {
        if (document.getElementById('smn-p-' + firstFromagerIndex) !== null) {
          document
            .getElementById('smn-p-' + firstFromagerIndex)
            .classList.add('smn-p-active');
        }
      } else {
        if (document.getElementById('smn-p-' + firstFromagerIndex) !== null) {
          document
            .getElementById('smn-p-' + firstFromagerIndex)
            .classList.remove('smn-p-active');
        }
      }
    }
  }, [
    props.disabledScenesIndexes,
    props.usefulIndexes.scene_index,
    props.usefulIndexes.classification_scene_index,
  ]);

  useEffect(() => {
    let firstFromagerIndex;
    let allFromagerDisabled = true;
    props.configuration.classifications_scenes[1].scenes.map((scene, index) => {
      if (scene.name === 'Le Fromager') {
        if (firstFromagerIndex === undefined) {
          firstFromagerIndex = index;
        }
        if (!props.disabledScenesIndexes.includes(index)) {
          allFromagerDisabled = false;
        }
      }
    });
    if (
      allFromagerDisabled &&
      document.getElementById('smn-p-' + firstFromagerIndex) !== null
    ) {
      document
        .getElementById('smn-p-' + firstFromagerIndex)
        .classList.add('smn-p-disabled');
    }
  }, [props.disabledScenesIndexes]);

  if (
    props.configuration.classifications_scenes[
      props.usefulIndexes.classification_scene_index
    ].scenes.length === 1
  ) {
    return <div className='smn-container'></div>;
  } else {
    return (
      <div className='smn-container'>
        {props.configuration.classifications_scenes[
          props.usefulIndexes.classification_scene_index
        ].scenes.map((scene, index) => {
          if (
            (index > 0 &&
              scene.name !==
                props.configuration.classifications_scenes[
                  props.usefulIndexes.classification_scene_index
                ].scenes[index - 1].name) ||
            index === 0
          ) {
            return (
              <div
                key={index}
                className='smn-subcontainer'
                onClick={() => {
                  if (
                    props.configuration.classifications_scenes[
                      props.usefulIndexes.classification_scene_index
                    ].scenes[index].name === 'Le Fromager'
                  ) {
                    if (!props.choice.disabledScenesIndexes.includes(index)) {
                      props.dispatch(
                        goToScene(props.configuration, props.choice, index)
                      );
                    } else if (
                      props.configuration.classifications_scenes[
                        props.usefulIndexes.classification_scene_index
                      ].scenes[index + 1].name === 'Le Fromager' &&
                      !props.choice.disabledScenesIndexes.includes(index + 1)
                    ) {
                      props.dispatch(
                        goToScene(props.configuration, props.choice, index + 1)
                      );
                    } else if (
                      props.configuration.classifications_scenes[
                        props.usefulIndexes.classification_scene_index
                      ].scenes[index + 2].name === 'Le Fromager' &&
                      !props.choice.disabledScenesIndexes.includes(index + 2)
                    ) {
                      props.dispatch(
                        goToScene(props.configuration, props.choice, index + 2)
                      );
                    } else {
                      props.dispatch(
                        goToScene(props.configuration, props.choice, index)
                      );
                    }
                  } else if (props.usefulIndexes.scene_index !== index) {
                    props.dispatch(
                      goToScene(props.configuration, props.choice, index)
                    );
                  }
                }}
              >
                <p
                  id={'smn-p-' + index}
                  className={
                    !props.choice.disabledScenesIndexes.includes(index)
                      ? props.usefulIndexes.scene_index === index
                        ? 'smn-p smn-p-active'
                        : 'smn-p'
                      : 'smn-p smn-p-disabled'
                  }
                >
                  {scene.name}
                </p>
                <pre className='smn-pre'>{'   /   '}</pre>
              </div>
            );
          }
        })}
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    configuration: state.configuration,
    choice: state.choice,
    usefulIndexes: state.usefulIndexes,
    disabledScenesIndexes: state.choice.disabledScenesIndexes,
  };
}

export default connect(mapStateToProps)(SubMainNav);
