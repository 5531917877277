import React, { useState } from 'react';
import '../styles/components/MainNav.css';
import { connect } from 'react-redux';
import { goToClassificationScene } from '../redux/actions/actionCreators';
import { saveProject } from '../redux/actions/userActions';
import { Link } from 'react-router-dom';

const MainNav = (props) => {
  const [loggeduser, setLoggeduser] = useState(
    localStorage.getItem('loggeduser')
  );

  const useradminlink = () => {
    return props.userrole == 'admin' ? (
      <Link to='/gestionutilisateur' className='mn-a'>
        GESTION UTILISATEURS
      </Link>
    ) : (
      <div></div>
    );
  };

  return (
    <div className='mn-container'>
      <div className='mn-navbar'>
        <Link to='/accueil' className='mn-a mn-a-previous'>
          ACCUEIL
        </Link>
        <div className='mn-vl' />
        <Link
          to={{
            pathname: '/formulaire',
            search:
              props.projects[props.usefulIndexes.project_index].project_name,
          }}
          className='mn-a mn-a-previous'>
          FORMULAIRE
        </Link>
        <div className='mn-vl' />
        {props.configuration.classifications_scenes.map(
          (classification_scene, index) => {
            return (
              <div key={index} className='mn-a-container'>
                <a
                  className={
                    props.current_mainscene === classification_scene.code
                      ? 'mn-a mn-a-active'
                      : props.classification_scene_index > 0
                      ? 'mn-a mn-a-previous'
                      : 'mn-a'
                  }
                  onClick={() =>
                    props.dispatch(
                      goToClassificationScene(
                        props.configuration,
                        props.choice,
                        classification_scene.code,
                        index
                      )
                    )
                  }>
                  {classification_scene.name}
                </a>
                <div className='mn-vl' />
              </div>
            );
          }
        )}
        <Link
          to='/synthese'
          className='mn-a'
          onClick={() => {
            props.dispatch(saveProject(props.choice, props.projects));
          }}>
          SYNTHÈSE
        </Link>
        <div className='mn-vl' />
        {/* {useradminlink()} */}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    configuration: state.configuration,
    choice: state.choice,
    projects: state.user.info.projects,
    current_mainscene: state.navigation.scene_current.code_classification,
    classification_scene_index: state.usefulIndexes.classification_scene_index,
    userrole: state.user.info.role,
    usefulIndexes: state.usefulIndexes,
  };
}

export default connect(mapStateToProps)(MainNav);
