import { SET_UP_CONFIGURATION } from './types';
import { UPDATE_CONFIGURATION } from './types';

// ACTION CREATORS

export function setUpConfiguration(data, vocation) {
  return {
    type: SET_UP_CONFIGURATION,
    payload: { data: data, vocation: vocation },
  };
}

export function updateConfiguration(data) {
  return {
    type: UPDATE_CONFIGURATION,
    payload: data,
  };
}
