import {
  SET_CURRENT_PROJECT_INDEX,
  SET_CLASSIFICATION_SCENE_INDEX,
  SET_SCENE_INDEX,
} from './types';

// ACTION CREATORS

export function setCurrentProjectIndex(index) {
  return {
    type: SET_CURRENT_PROJECT_INDEX,
    payload: index,
  };
}

export function setClassificationSceneIndex(index) {
  return {
    type: SET_CLASSIFICATION_SCENE_INDEX,
    payload: index,
  };
}

export function setSceneIndex(index) {
  return {
    type: SET_SCENE_INDEX,
    payload: index,
  };
}
