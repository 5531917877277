/**
 * Created by mnassih on 06/10/2020.
 */

import React, { useState, useEffect } from 'react';
import { UserForm } from '../components/auth';
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/pages/Parametres.css';
import { Header } from '../components';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchAllUsers } from '../redux/actions/adminAction';
import {
  faUsers,
  faUserPlus,
  faPlusCircle,
  faProjectDiagram,
  faEdit,
  faBan,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/images/Logo.png';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Loader from 'react-loader-spinner';
import { removeuser } from '../redux/actions/adminAction';
import '../styles/pages/UserManagement.css';

const UserManagement = (props) => {
  var gridApi;
  const initialPageSize = 5;
  const [gridEditApi, setGridEditApi] = useState({});
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridLoaded, setGridLoaded] = useState(true);
  const [open, setOpen] = useState(false);
  const [userSelected, setUserSelected] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [isNew, setIsNew] = useState(true);

  const closeModal = () => {
    setGridLoaded(false);
    setOpen(false);
    setTimeout(() => {
      callApi();
    }, 1000);
  };

  useEffect(() => {
    callApi();
  }, []);

  const onGridReady = (params) => {
    console.log('GRID READY');
    gridApi = params.api;
    setGridEditApi(params.api);
    setGridColumnApi(params.columnApi);
    gridApi.sizeColumnsToFit();
    gridApi.paginationSetPageSize(pageSize);
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    setPageSize(Number(value));
    gridEditApi.paginationSetPageSize(pageSize);
  };

  function onFilterTextBoxChanged(e) {
    gridEditApi.setQuickFilter(e.target.value);
  }

  function buttonRenderer() {
    return (
      <div>
        <a onClick={onButtonClick} className='action-button'>
          <FontAwesomeIcon className='parametres-table-btn1' icon={faEdit} />
        </a>
        {'        '}
        <a onClick={submit} className='action-button'>
          <FontAwesomeIcon className='parametres-table-btn2' icon={faTimes} />
        </a>
      </div>
    );
  }

  const pageNumberMap = () => {
    let numberArray = [];
    for (let i = 5; i < 100; i += 10) {
      numberArray.push({ value: i });
    }
    return numberArray;
  };

  const callApi = async () => {
    console.log(
      'process.env.REACT_APP_QUABLE_API_HOST',
      process.env.REACT_APP_BO_API_HOST
    );
    props.dispatch(fetchAllUsers()).then(() => {
      setGridLoaded(true);
    });
  };

  const onAddUserClick = (e) => {
    console.log('button cliqued');
    setUserSelected({
      givenName: '',
      familyName: '',
      email: '',
      role: 'user',
      passwordconfirm: '',
      password: '',
    });
    setIsNew(true);
    setOpen((o) => !o);
  };
  const onButtonClick = (e) => {
    const selectedNodes = gridApi.getSelectedNodes();
    console.log(selectedNodes);
    const selectedData = selectedNodes.map((node) => node.data);
    console.log(selectedData);
    const selectedDataStringPresentation = selectedData
      .map((node) => node.user + ' ' + node.model)
      .join(', ');
    //alert(`Selected nodes: ${selectedDataStringPresentation}`)
    selectedData[0].passwordconfirm = selectedData[0].password;
    setUserSelected(selectedData[0]);
    setIsNew(false);
    setOpen((o) => !o);
  };

  const onDeleteClick = (e) => {
    const selectedNodes = gridApi.getSelectedNodes();
    console.log(selectedNodes);
    const selectedData = selectedNodes.map((node) => node.data);
    console.log(selectedData);
    console.log(selectedData[0]._id);
    props.dispatch(removeuser(selectedData[0]._id)).then(() => {
      setTimeout(() => {
        callApi();
      }, 1000);
    });
  };

  const submit = () => {
    confirmAlert({
      title: 'Confirmation',
      message:
        'La suppression de cet élément est irréversible et ne peut être annulée',
      buttons: [
        {
          label: 'Confirmer',
          onClick: () => onDeleteClick(),
        },
        {
          label: 'Annuler',
          onClick: () => {},
        },
      ],
    });
  };

  const columnDefs = [
    {
      headerName: 'Nom',
      field: 'familyName',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },
    {
      headerName: 'Prenom',
      field: 'givenName',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },
    {
      headerName: 'Role',
      field: 'role',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },
      {
          headerName: 'PDV',
          field: 'pdv',
          sortable: true,
          unSortIcon: true,
          resizable: true,
      },
      {
          headerName: 'Région',
          field: 'region',
          sortable: true,
          unSortIcon: true,
          resizable: true,
      },
    {
      headerName: 'Action',
      field: 'action',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellRenderer: 'buttonRenderer',
    },
  ];

  /***
   *
   */

  return (
    <div>
      <div className='um-header'>
        <Link className='um-header-logo' to='/accueil'>
          <img
            className='um-header-logo-img'
            src={logo}
            width='101'
            height='120'
          />
        </Link>
        <div>
          <h1 className='um-header-title'>
            MON APPLI <br />
            <span>MISE EN PROJET</span> <br />
            FAB'MAG
          </h1>
        </div>
        <p className='um-header-heading'>GESTION DES UTILISATEURS</p>
      </div>
      <div className='usermanagement_maincontent'>
        <Row className='usermanagement_tableheader'>
          <Col xs='12'>Liste des utilisateurs</Col>
        </Row>
        <Row className='row_bottom_margin'>
          <Col xs='4'></Col>
          <Col xs='4'>
            <input
              type='text'
              id='filter-text-box'
              placeholder='Rechercher un utilisateur'
              onInput={onFilterTextBoxChanged}
              className='grid-search-box'
            />
          </Col>
          <Col xs='4'>
            {' '}
            <Button variant='danger' onClick={onAddUserClick}>
              Ajouter un utilisateur
            </Button>
          </Col>
        </Row>
        <div className='ag-theme-alpine' style={{ width: '100%' }}>
          <AgGridReact
            columnDefs={columnDefs}
            frameworkComponents={{ buttonRenderer: buttonRenderer }}
            rowData={props.userlist}
            pagination={true}
            paginationPageSize={initialPageSize}
            domLayout='autoHeight'
            onGridReady={onGridReady}
            rowSelection='single'></AgGridReact>
          <Row className='page-size-select-row'>
            <Col xs='12'>
              Afficher
              <select
                onChange={() => onPageSizeChanged()}
                id='page-size'
                className='page-size-select'>
                {pageNumberMap().map((increment) =>
                  increment.value == pageSize ? (
                    <option value={increment.value} selected>
                      {increment.value}
                    </option>
                  ) : (
                    <option value={increment.value}>{increment.value}</option>
                  )
                )}
              </select>{' '}
              enregistrements par page
            </Col>
          </Row>
        </div>
      </div>
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={closeModal}
        contentStyle={{
          width: '38%',
          margin: 'auto',
          border: '1px solid black',

          height: '650px',
          borderRadius: '5px',
        }}
        arrow={true}>
        {(close) => (
          <UserForm
            userSelected={userSelected}
            closePopUP={close}
            isNew={isNew}
          />
        )}
      </Popup>
    </div>
  );
};

function mapStateToProps(state) {
  console.log(state);
  return {
    userlist: state.admin.users,
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  UserManagement
);
