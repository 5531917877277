import React, { useState } from 'react';
import '../styles/pages/FirstMenu.css';
import logo from '../assets/images/Logo.png';
import concept from '../assets/icons/concept.png';
import projets from '../assets/icons/projets.png';
import nouveau from '../assets/icons/nouveau.png';

import concept_blanc from '../assets/icons/concept_blanc.png';
import projets_blanc from '../assets/icons/projets_blanc.png';
import nouveau_blanc from '../assets/icons/nouveau_blanc.png';

import { Link } from 'react-router-dom';
import Pdf from '../assets/files/Fevrier2021_GuideConcept.pdf';
import { connect } from 'react-redux';

const FirstMenu = (props) => {
  const [hoveredItem, setHoveredItem] = useState();

  function handleMouseOver(item) {
    setHoveredItem(item);
  }

  function handleMouseLeave(item) {
    if (hoveredItem === item) {
      setHoveredItem(0);
    }
  }

  const adminmenu = () => {
    return props.userrole == 'admin' ? (
      <div id='manageusers'>
        {' '}
        <Link to='/gestionutilisateur' className='mn-a'>
          GESTION UTILISATEURS
        </Link>
      </div>
    ) : (
      ''
    );
  };

  function handleConceptClick() {}

  function handleProjetsClick() {}

  function handleNewProjectClick() {}

  return (
    <div className='fm-container'>
      <div className='fm-header'>
        <Link className='fm-header-logo' to='/accueil'>
          <img
            className='fm-header-logo-img'
            src={logo}
            width='101'
            height='120'
          />
        </Link>
        <div>
          <h1 className='fm-header-title'>
            MON APPLI <br />
            <span>MISE EN PROJET</span> <br />
            FAB'MAG
          </h1>
        </div>
        <p className='fm-header-heading'>ACCUEIL</p>
        {adminmenu()}
      </div>
      <div className='fm-menu'>
        <Link
          to={Pdf}
          target='_blank'
          id='fm-menu-firstelement'
          className='fm-menu-element'
          onMouseOver={() => handleMouseOver(1)}
          onMouseLeave={() => handleMouseLeave(1)}
          onClick={() => handleConceptClick()}
        >
          <div>
            <img
              src={hoveredItem === 1 ? concept_blanc : concept}
              height={112.5}
            ></img>
            <p className='fm-menu-element-p'>LE CONCEPT FAB'MAG</p>
          </div>
        </Link>
        <Link
          to='/mesprojetsencours'
          id='fm-menu-secondelement'
          className='fm-menu-element'
          onMouseOver={() => handleMouseOver(2)}
          onMouseLeave={() => handleMouseLeave(2)}
          onClick={() => handleProjetsClick()}
        >
          <div>
            <img
              src={hoveredItem === 2 ? projets_blanc : projets}
              height={112.5}
            ></img>
            <p className='fm-menu-element-p'>MES PROJETS EN COURS</p>
          </div>
        </Link>
        <Link
          to='/creationprojet'
          id='fm-menu-thirdelement'
          className='fm-menu-element'
          onMouseOver={() => handleMouseOver(3)}
          onMouseLeave={() => handleMouseLeave(3)}
          onClick={() => handleNewProjectClick()}
        >
          <div>
            <img
              src={hoveredItem === 3 ? nouveau_blanc : nouveau}
              height={112.5}
            ></img>
            <p className='fm-menu-element-p'>CRÉER UN NOUVEAU PROJET</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userrole: state.admin.loggeduser.role,
  };
}

export default connect(mapStateToProps)(FirstMenu);
