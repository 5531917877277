import React, { useState, useEffect } from 'react';
import MainNav from './MainNav';
import '../styles/components/Header.css';
import logo from '../assets/images/Logo.png';
import Select from 'react-select/';
import arrow from '../assets/icons/Rectangle.png';
import { addProject, saveProject } from '../redux/actions/userActions';
import { selectProject } from '../redux/actions/actionCreators';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCurrentProjectIndex } from '../redux/actions/usefulIndexesActions';
import SavePopup from './SavePopup';
import logout from '../assets/icons/logout.png';
import configuration from '../script/output.json';
import configuration_safari from '../script/safari/output.json';
import { setUpConfiguration } from '../redux/actions/configurationActions';

const Header = (props) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [loggeduser, setLoggeduser] = useState(
    localStorage.getItem('loggeduser')
  );

  const [savePopupOpened, setSavePopupOpened] = useState(false);

  function closeSavePopup() {
    setSavePopupOpened(false);
  }

  useEffect(() => {
    let options = [];
    props.projects.map((project, index) => {
      options.push({
        value: project.project_name,
        label: project.project_name,
      });
    });
    setOptions(options);
    if (props.location) {
      if (props.projects.length > 0) {
        if (
          props.location.from !== undefined &&
          props.location.from === 'synthese'
        ) {
          setSelectedOption(props.usefulIndexes.project_index);
        } else if (props.location.from === 'myprojects') {
          setSelectedOption(props.location.project_index);
        } else {
          props.dispatch(
            selectProject(
              props.configuration,
              props.projects,
              options[options.length - 1].value
            )
          );
          setSelectedOption(options.length - 1);
          props.dispatch(setCurrentProjectIndex(options.length - 1));
        }
      }
    }
  }, [props.projects]);

  function handleChange(selectedOption) {
    setSelectedOption(selectedOption);
    let classifications_scenes;
    configuration.map((data) => {
      if (
        data.vocation ===
        props.projects[
          options.indexOf(selectedOption)
        ].project_info.informationsGenerales.values.typeDePointDeVente.value.toUpperCase()
      ) {
        // Detect Chrome
        let chromeAgent = navigator.userAgent.indexOf('Chrome') > -1;
        // Detect Safari
        let safariAgent = navigator.userAgent.indexOf('Safari') > -1;
        // Discard Safari since it also matches Chrome
        if (chromeAgent && safariAgent) safariAgent = false;
        if (!safariAgent) {
          props.dispatch(setUpConfiguration(configuration, data.vocation));
        } else {
          props.dispatch(
            setUpConfiguration(configuration_safari, data.vocation)
          );
        }
        classifications_scenes = data.classification_scenes;
      }
    });

    let config = { classifications_scenes: classifications_scenes };

    props.dispatch(setCurrentProjectIndex(options.indexOf(selectedOption)));
    if (selectedOption !== null) {
      props.dispatch(
        selectProject(config, props.projects, selectedOption.value)
      );
    }
  }

  function handleSave() {
    setSavePopupOpened(true);
    props.dispatch(saveProject(props.choice, props.projects));
  }



  const showSaveButton=()=>{
    return (props.currentuser.profil!=3)?(
        <button
        className='header-save-btn'
        onClick={handleSave}
        type='submit'>
      ENREGISTRER
     </button>):('')
  }
  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      width: 325,
      height: 50,
      fontSize: 16,
      backgroundColor: 'rgba(0,0,0,0.08)',
      paddingLeft: 68,
      fontFamily: 'OpenSans-Bold',
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
      cursor: 'text',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      position: 'absolute',
      textAlign: 'center',
      right: 50,
      color: !state.isFocused ? 'transparent' : 'grey',
      '&:hover': {
        color: '#aaaaaa',
      },
    }),
    dropdownIndicator: () => ({
      color: 'transparent',
      marginLeft: 2,
      width: 50,
      height: 50,
      background: 'rgba(0,0,0,0.08) url(' + arrow + ') no-repeat center center',
      cursor: 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      paddingLeft: 68,
      backgroundColor: isFocused ? 'rgba(0,0,0,0.16)' : null,
      color: 'black',
      cursor: 'pointer',
    }),
  };

  const handleLogout = () => {
    localStorage.clear();
    props.history.push('/connexion');
  };

  const headercontent = () => {
    return (
      <div className='header-part2'>
        <div className='header-part2-top'>
          <Select
            placeholder='Tapez pour chercher/créer un projet'
            styles={customStyles}
            value={options[selectedOption]}
            onChange={handleChange}
            options={options}
            // onCreateOption={handleCreate}
            // isClearable
          />
            {showSaveButton()}
          <img className='logoutIcon' src={logout} onClick={handleLogout} />
        </div>
        <MainNav />
      </div>
    );
  };

  return (
    <div className='header'>
      <SavePopup
        success={props.success}
        open={savePopupOpened}
        onClose={closeSavePopup}
      />
      <Link className='header-logo' to='/accueil'>
        <img className='header-logo-img' src={logo} width='101' height='120' />
      </Link>
      <div>
        <h1 className='header-title'>
          MON APPLI <br />
          <span>MISE EN PROJET</span> <br />
          FAB'MAG
        </h1>
      </div>

      {headercontent()}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    choice: state.choice,
    projects: state.user.info.projects,
    configuration: state.configuration,
    usefulIndexes: state.usefulIndexes,
    success: state.user.success,
      currentuser: state.admin.loggeduser
  };
}

export default connect(mapStateToProps)(Header);
