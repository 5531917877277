import {
  SET_CURRENT_PROJECT_INDEX,
  SET_CLASSIFICATION_SCENE_INDEX,
  SET_SCENE_INDEX,
} from '../actions/types';

const initialState = {
  project_index: 0,
  classification_scene_index: 0,
  scene_index: 0,
};

export function usefulIndexesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_PROJECT_INDEX:
      return Object.assign({}, state, {
        project_index: action.payload,
        classification_scene_index: state.classification_scene_index,
        scene_index: state.scene_index,
      });
    case SET_CLASSIFICATION_SCENE_INDEX:
      return Object.assign({}, state, {
        project_index: state.project_index,
        classification_scene_index: action.payload,
        scene_index: state.scene_index,
      });
    case SET_SCENE_INDEX:
      return Object.assign({}, state, {
        project_index: state.project_index,
        classification_scene_index: state.classification_scene_index,
        scene_index: action.payload,
      });
    default:
      return state;
  }
}
