import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/Logo.png';
import arrow from '../assets/icons/fleche_droite.png';
import '../styles/pages/MyProjects.css';
import { connect } from 'react-redux';
import { selectProject } from '../redux/actions/actionCreators';
import { setCurrentProjectIndex } from '../redux/actions/usefulIndexesActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import configuration from '../script/output.json';
import configuration_safari from '../script/safari/output.json';
import { setUpConfiguration } from '../redux/actions/configurationActions';

const MyProjects = (props) => {
  function projectData(project) {
    return props.currentuser.profil == 2 ? (
      <div className='mp-project-title'>
        <p className='mp-project-title-date'>{project.dateCreated}</p>
        <p className='mp-project-title-name'>
          PDV - {project.pdv} / {project.project_name}
        </p>
      </div>
    ) : (
      <div className='mp-project-title'>
        <p className='mp-project-title-date'>{project.dateCreated}</p>
        <p className='mp-project-title-name'> {project.project_name}</p>
      </div>
    );
  }

  function handleSelectProject(project_name, index) {
    let classifications_scenes;

    // Detect Chrome
    let chromeAgent = navigator.userAgent.indexOf('Chrome') > -1;
    // Detect Safari
    let safariAgent = navigator.userAgent.indexOf('Safari') > -1;

    // Discard Safari since it also matches Chrome
    if (chromeAgent && safariAgent) safariAgent = false;
    if (!safariAgent) {
      configuration.map((data) => {
        if (
          data.vocation ===
          props.projects[
            index
          ].project_info.informationsGenerales.values.typeDePointDeVente.value.toUpperCase()
        ) {
          props.dispatch(setUpConfiguration(configuration, data.vocation));
          classifications_scenes = data.classification_scenes;
        }
      });
    } else {
      configuration_safari.map((data) => {
        if (
          data.vocation ===
          props.projects[
            index
          ].project_info.informationsGenerales.values.typeDePointDeVente.value.toUpperCase()
        ) {
          props.dispatch(
            setUpConfiguration(configuration_safari, data.vocation)
          );
          classifications_scenes = data.classification_scenes;
        }
      });
    }

    let config = { classifications_scenes: classifications_scenes };

    props.dispatch(setCurrentProjectIndex(index));
    props.dispatch(selectProject(config, props.projects, project_name));
  }
  return (
    <div className='mp-container'>
      <div className='mp-header'>
        <Link className='mp-header-logo' to='/accueil'>
          <img
            className='mp-header-logo-img'
            src={logo}
            width='101'
            height='120'
          />
        </Link>
        <div>
          <h1 className='mp-header-title'>
            MON APPLI <br />
            <span>MISE EN PROJET</span> <br />
            FAB'MAG
          </h1>
        </div>
        <p className='mp-header-heading'>MES PROJETS EN COURS</p>
      </div>
      <div className='mp-projects-container'>
        <div className='mp-projects-title'>
          <p className='mp-projects-title-p'>PROJETS EN COURS :</p>
        </div>
        <p
          style={
            props.projects.length > 0
              ? { display: 'none' }
              : { display: 'block' }
          }
        >
          Pas de projets en cours
        </p>
        {props.projects.map((project, index) => {
          return (
            <Link
              key={index}
              to={{
                pathname: '/projet',
                from: 'myprojects',
                project_index: index,
              }}
              className='mp-project'
              onClick={() => handleSelectProject(project.project_name, index)}
            >
              {projectData(project)}
              <div className='mp-project-right'>
                <Link
                  to={{
                    pathname: '/edit-project',
                    search: project.project_name,
                  }}
                >
                  <FontAwesomeIcon className='mp-project-edit' icon={faEdit} />
                </Link>
                <img className='mp-project-arrow' src={arrow}></img>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  //console.log(state.user.info.projects,"state.user.info.projects")
  return {
    configuration: state.configuration,
    projects: state.admin.loggeduser.projects,
    currentuser: state.admin.loggeduser,
  };
}

export default connect(mapStateToProps)(MyProjects);
