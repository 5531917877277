import { INIT_SCENE, UPDATE_SCENE } from '../actions/types';
import scene from '../tests/scene.json';
const initialState = null;

export function sceneReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_SCENE:
      return Object.assign({}, state, {
        img_root_path: action.payload['img_root_path'],
        possibilities_rendered: action.payload['possibilities_rendered'],
      });
    case UPDATE_SCENE:
      return Object.assign({}, state, {
        img_root_path: action.payload.img_root_path,
        possibilities_rendered: action.payload.possibilities_rendered,
      });
    default:
      return state;
  }
}
