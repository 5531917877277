import React from 'react';
import '../styles/components/RelativeNav.css';
import { connect } from 'react-redux';
import { nextScene, previousScene } from '../redux/actions/actionCreators';
import { withRouter } from 'react-router-dom';
import { saveProject } from '../redux/actions/userActions';
import {
  addDisabledSceneIndex,
  removeDisabledSceneIndex,
} from '../redux/actions/choiceActions';

const RelativeNav = (props) => {
  let removeMsg = 'NE CONCERNE PAS MON POINT DE VENTE';
  let addMsg = 'AJOUTER DANS LE PROJET';

  function handlePreviousStepClick() {
    props.dispatch(
      previousScene(props.configuration, props.choice, props.usefulIndexes)
    );
  }

  function handleNextStepClick() {
    if (
      props.usefulIndexes.scene_index ===
        props.configuration.classifications_scenes[1].scenes.length - 1 &&
      (props.usefulIndexes.scene_index !== 0 ||
        props.usefulIndexes.classification_scene_index === 1)
    ) {
      props.dispatch(saveProject(props.choice, props.projects));
      props.history.push('/synthese');
    } else {
      props.dispatch(
        nextScene(props.configuration, props.choice, props.usefulIndexes)
      );
    }
  }

  function disableSceneFromProject() {
    if (
      props.configuration.classifications_scenes[1].scenes[
        props.usefulIndexes.scene_index
      ].name === 'Le Fromager'
    ) {
      let disabledNb = 0;
      let fromagerScenesNb = 0;
      props.configuration.classifications_scenes[1].scenes.map(
        (scene, index) => {
          if (scene.name === 'Le Fromager') {
            fromagerScenesNb++;
            if (props.choice.disabledScenesIndexes.includes(index)) {
              disabledNb++;
            } else {
              props.dispatch(addDisabledSceneIndex(index));
            }
          }
        }
      );
      let first = true;
      props.configuration.classifications_scenes[1].scenes.map(
        (scene, index) => {
          if (
            scene.name === 'Le Fromager' &&
            disabledNb === fromagerScenesNb &&
            first
          ) {
            props.dispatch(removeDisabledSceneIndex(index));
            first = false;
          }
        }
      );
      handleNextStepClick();
    } else {
      if (
        props.choice.disabledScenesIndexes.includes(
          props.usefulIndexes.scene_index
        )
      ) {
        props.dispatch(
          removeDisabledSceneIndex(props.usefulIndexes.scene_index)
        );
      } else {
        props.dispatch(addDisabledSceneIndex(props.usefulIndexes.scene_index));
        handleNextStepClick();
      }
    }
  }

  return (
    <div className='rn-container'>
      <button
        className='rn-btn-left'
        style={{
          visibility:
            props.usefulIndexes.classification_scene_index === 0 &&
            props.usefulIndexes.scene_index === 0
              ? 'hidden'
              : 'visible',
        }}
        onClick={handlePreviousStepClick}>
        ÉTAPE PRÉCÉDENTE
      </button>
      <div className='rn-btn-right-container'>
        <button
          className='rn-btn-firstright'
          style={
            props.usefulIndexes.classification_scene_index === 0
              ? { display: 'none' }
              : { display: 'block' }
          }
          onClick={() => disableSceneFromProject()}>
          {props.choice.disabledScenesIndexes.includes(
            props.usefulIndexes.scene_index
          )
            ? addMsg
            : removeMsg}
        </button>
        <button className='rn-btn-right' onClick={handleNextStepClick}>
          ÉTAPE SUIVANTE
        </button>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    configuration: state.configuration,
    choice: state.choice,
    usefulIndexes: state.usefulIndexes,
    projects: state.user.info.projects,
  };
}

export default withRouter(connect(mapStateToProps)(RelativeNav));
