import React from 'react';
import { Header, Scene } from '../components/index';
import { connect } from 'react-redux';
import '../styles/pages/Project.css';

const Project = (props) => {
  return (
    <div>
      <Header location={props.location} history={props.history} />
      <Scene />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    configuration: state.configuration,
  };
}

export default connect(mapStateToProps)(Project);
