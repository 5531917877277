import React, { useState, useEffect } from 'react';
import '../styles/components/ChangeScene.css';
import TypeModification from './TypeModification';
import { connect } from 'react-redux';
import {
  addDisabledSceneIndex,
  removeDisabledSceneIndex,
} from '../redux/actions/choiceActions';
import { goToScene } from '../redux/actions/actionCreators';

const ChangeScene = (props) => {
  useEffect(() => {
    let subcontainerdiv = document.getElementById('cs-subcontainer-id');
    subcontainerdiv.scrollTop = 0;
  }, [props.elements_modifies]);

  useEffect(() => {
    let first = true;
    let alreadyInitialised = false;
    props.configuration.classifications_scenes[1].scenes.map((scene, index) => {
      if (scene.name === 'Le Fromager') {
        if (props.disabledScenesIndexes.includes(index)) {
          alreadyInitialised = true;
        }
      }
    });
    if (!alreadyInitialised) {
      props.configuration.classifications_scenes[1].scenes.map(
        (scene, index) => {
          if (scene.name === 'Le Fromager') {
            if (!first && !props.disabledScenesIndexes.includes(index)) {
              props.dispatch(addDisabledSceneIndex(index));
            }
            first = false;
          }
        }
      );
    }
  }, []);

  function handleClick(index) {
    props.configuration.classifications_scenes[1].scenes.map((scene, i) => {
      if (scene.name === 'Le Fromager') {
        if (index !== i && !props.disabledScenesIndexes.includes(i)) {
          props.dispatch(addDisabledSceneIndex(i));
        }
      }
    });
    props.dispatch(removeDisabledSceneIndex(index));
    props.dispatch(goToScene(props.configuration, props.choice, index));
  }

  if (props.scene.name === 'Le Fromager') {
    return (
      <div className='cs-container'>
        <div className='cs-title'>
          <h1 className='cs-h1'>{props.scene.name}</h1>
        </div>
        <div
          id='cs-subcontainer-id'
          className='cs-subcontainer'
          style={{ height: props.height }}
        >
          <h2 className='tm-h2'>IMPLANTATION</h2>
          <div className='p-possibilities-avecsans-radio-container'>
            {props.configuration.classifications_scenes[1].scenes.map(
              (scene, index) => {
                if (scene.name === 'Le Fromager') {
                  return (
                    <div
                      key={index}
                      id={'p-radio-subcontainer-' + scene.code}
                      className={
                        index === props.usefulIndexes.scene_index
                          ? 'p-radio-subcontainer p-radio-subcontainer-active p-radio-margin-right'
                          : 'p-radio-subcontainer p-radio-margin-right'
                      }
                      onClick={() => handleClick(index)}
                    >
                      <input
                        id={'p-radio-' + scene.code}
                        className={
                          index === props.usefulIndexes.scene_index
                            ? 'p-radio p-radio-0'
                            : 'p-radio'
                        }
                        type='radio'
                        name={'pdv-type' + scene.code}
                        value={scene.code}
                        checked={
                          index === props.usefulIndexes.scene_index
                            ? true
                            : false
                        }
                      />
                      <label
                        id={'p-radio-label-' + scene.code}
                        className={
                          index === props.usefulIndexes.scene_index
                            ? 'p-radio-label p-radio-label-active'
                            : 'p-radio-label'
                        }
                        htmlFor={scene.code}
                      >
                        {scene.code.includes('B')
                          ? 'FRAIS EMBALLE'
                          : scene.code.includes('C')
                          ? 'KIOSQUE'
                          : 'TRAD'}
                      </label>
                    </div>
                  );
                }
              }
            )}
          </div>
          {props.elements_modifies.map((element, index) => {
            return (
              <TypeModification
                key={index}
                elements={element}
                element_modif_index={index}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className='cs-container'>
        <div className='cs-title'>
          <h1 className='cs-h1'>{props.scene.name}</h1>
        </div>
        <div
          id='cs-subcontainer-id'
          className='cs-subcontainer'
          style={{ height: props.height }}
        >
          {props.elements_modifies.map((element, index) => {
            return (
              <TypeModification
                key={index}
                elements={element}
                element_modif_index={index}
              />
            );
          })}
        </div>
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    configuration: state.configuration,
    choice: state.choice,
    scene:
      state.configuration.classifications_scenes[
        state.usefulIndexes.classification_scene_index
      ].scenes[state.usefulIndexes.scene_index],
    elements_modifies:
      state.configuration.classifications_scenes[
        state.usefulIndexes.classification_scene_index
      ].scenes[state.usefulIndexes.scene_index].elements_modifies,
    disabledScenesIndexes: state.choice.disabledScenesIndexes,
    usefulIndexes: state.usefulIndexes,
  };
}

export default connect(mapStateToProps)(ChangeScene);
