import { setTotalChoice } from './choiceActions';
import { initScene } from './sceneActions';
import { navigate } from './navigationActions';

import { updateScene } from './sceneActions';
import { setPossibilityChoice } from './choiceActions';
import {
  setClassificationSceneIndex,
  setSceneIndex,
} from './usefulIndexesActions';

function getProjectChoice(project_name, projects) {
  let project_index;
  let project = projects.map((project, index) => {
    if (project.project_name === project_name) {
      project_index = index;
      return project;
    }
  });
  return project[project_index];
}

function getProjectFirstScene(configuration, project) {
  let img_root_path =
    configuration.classifications_scenes[0].scenes[0].img_root_path;
  let possibilities_rendered = [];
  configuration.classifications_scenes[0].scenes[0].elements_modifies.forEach(
    (element_modifie, index1) => {
      let code;
      let element_modif_code = element_modifie.code;
      let img_path;
      element_modifie.types_modifications.forEach(
        (type_modification, index2) => {
          if (
            type_modification.code ===
            project.classifications_scenes[0].scenes[0].elements_modifies[
              index1
            ].types_modifications.code
          ) {
            type_modification.possibilities.forEach((possibility, index3) => {
              if (
                possibility.code ===
                project.classifications_scenes[0].scenes[0].elements_modifies[
                  index1
                ].types_modifications.possibilities.code
              ) {
                code = possibility.code;
                img_path = possibility.img_scene_path;
              }
            });
          }
        }
      );
      possibilities_rendered.push({
        code: code,
        element_modif_code: element_modif_code,
        img_path: img_path,
      });
    }
  );
  return {
    img_root_path: img_root_path,
    possibilities_rendered: possibilities_rendered,
  };
}

export function selectProject(configuration, projects, project_name) {
  let project = getProjectChoice(project_name, projects);
  let scene = getProjectFirstScene(configuration, project);
  return (dispatch) => {
    dispatch(
      navigate({
        code_classification: project.classifications_scenes[0].code,
        code_scene: project.classifications_scenes[0].scenes[0].code,
      })
    );
    dispatch(setClassificationSceneIndex(0));
    dispatch(setSceneIndex(0));
    dispatch(initScene(scene));
    dispatch(setTotalChoice(project));
  };
}

function updateChoice(
  navigation,
  choice,
  element_modif_code,
  type_modification_code,
  possibility_code
) {
  let index1, index2, index3;
  let newChoice = JSON.parse(JSON.stringify(choice));
  choice.classifications_scenes.forEach((classification_scene, index) => {
    if (
      navigation.scene_current.code_classification === classification_scene.code
    ) {
      index1 = index;
      classification_scene.scenes.forEach((scene, index) => {
        if (navigation.scene_current.code_scene === scene.code) {
          index2 = index;
          scene.elements_modifies.forEach((element_modifie, index) => {
            if (element_modif_code === element_modifie.code) {
              index3 = index;
            }
          });
        }
      });
    }
  });
  newChoice.classifications_scenes[index1].scenes[index2].elements_modifies[
    index3
  ].types_modifications.code = type_modification_code;
  newChoice.classifications_scenes[index1].scenes[index2].elements_modifies[
    index3
  ].types_modifications.possibilities.code = possibility_code;
  if (
    newChoice.classifications_scenes[index1].scenes[index2].code === '0110' &&
    (possibility_code === 'CHROMATIC_CH2_1039' ||
      possibility_code === 'KNAUF_ORGANIC_GRIS_PIERRE')
  ) {
    newChoice.classifications_scenes[1].scenes[0].elements_modifies[0].types_modifications.possibilities.code =
      'HAUTEUR_2300_' + possibility_code;
  }
  return newChoice;
}

function updateChoiceWithPose(
  navigation,
  choice,
  element_modif_code,
  type_modification_code,
  possibility_code,
  pose_code
) {
  let index1, index2, index3;
  let newChoice = JSON.parse(JSON.stringify(choice));
  choice.classifications_scenes.forEach((classification_scene, index) => {
    if (
      navigation.scene_current.code_classification === classification_scene.code
    ) {
      index1 = index;
      classification_scene.scenes.forEach((scene, index) => {
        if (navigation.scene_current.code_scene === scene.code) {
          index2 = index;
          scene.elements_modifies.forEach((element_modifie, index) => {
            if (element_modif_code === element_modifie.code) {
              index3 = index;
            }
          });
        }
      });
    }
  });
  newChoice.classifications_scenes[index1].scenes[index2].elements_modifies[
    index3
  ].types_modifications.code = type_modification_code;
  newChoice.classifications_scenes[index1].scenes[index2].elements_modifies[
    index3
  ].types_modifications.possibilities.code = possibility_code;
  newChoice.classifications_scenes[index1].scenes[index2].elements_modifies[
    index3
  ].types_modifications.possibilities.poses.code = pose_code;
  return newChoice;
}

function getUpdatedScene(scene, element_modif_code, code, img_path) {
  let pr = [];
  scene.possibilities_rendered.forEach((element, index) => {
    if (element['element_modif_code'] === element_modif_code) {
      pr.push({
        code: code,
        element_modif_code: element.element_modif_code,
        img_path: img_path,
      });
    } else {
      pr.push({
        code: element.code,
        element_modif_code: element.element_modif_code,
        img_path: element.img_path,
      });
    }
  });

  return {
    img_root_path: scene.img_root_path,
    possibilities_rendered: pr,
  };
}

export function modifyScene(
  navigation,
  scene,
  choice,
  type_modification_code,
  possibility_code,
  pose_code,
  element_modif_code,
  img_path
) {
  return (dispatch) => {
    dispatch(
      updateScene(
        getUpdatedScene(scene, element_modif_code, possibility_code, img_path)
      )
    );
    if (pose_code === null) {
      dispatch(
        setPossibilityChoice(
          updateChoice(
            navigation,
            choice,
            element_modif_code,
            type_modification_code,
            possibility_code
          )
        )
      );
    } else {
      dispatch(
        setPossibilityChoice(
          updateChoiceWithPose(
            navigation,
            choice,
            element_modif_code,
            type_modification_code,
            possibility_code,
            pose_code
          )
        )
      );
    }
  };
}

function getDefaultImagesForScene(configuration, choice, csi, si) {
  let possibilities_rendered = [];
  choice.classifications_scenes[0].scenes[0].elements_modifies.forEach(
    (element_modifie, index1) => {
      possibilities_rendered.push({
        code: element_modifie.types_modifications.possibilities.code,
        element_modif_code: element_modifie.code,
        img_path:
          configuration.classifications_scenes[csi].scenes[si].default_imgs[
            element_modifie.types_modifications.possibilities.code
          ],
      });
    }
  );
  return possibilities_rendered;
}

function getNextOrPreviousScene(configuration, choice, csi, si) {
  let img_root_path =
    configuration.classifications_scenes[csi].scenes[si].img_root_path;
  let possibilities_rendered = [];
  if (csi !== 0) {
    possibilities_rendered = getDefaultImagesForScene(
      configuration,
      choice,
      csi,
      si
    );
  }
  configuration.classifications_scenes[csi].scenes[
    si
  ].elements_modifies.forEach((element_modifie, index1) => {
    element_modifie.types_modifications.forEach((type_modification, index2) => {
      if (
        type_modification.code ===
        choice.classifications_scenes[csi].scenes[si].elements_modifies[index1]
          .types_modifications.code
      ) {
        type_modification.possibilities.forEach((possibility, index3) => {
          if (
            possibility.code ===
            choice.classifications_scenes[csi].scenes[si].elements_modifies[
              index1
            ].types_modifications.possibilities.code
          ) {
            if (possibility.poses !== undefined) {
              possibility.poses.forEach((pose, index) => {
                if (
                  pose.code ===
                  choice.classifications_scenes[csi].scenes[si]
                    .elements_modifies[index1].types_modifications.possibilities
                    .poses.code
                ) {
                  possibilities_rendered.push({
                    code: possibility.code,
                    element_modif_code: element_modifie.code,
                    img_path: pose.img_scene_path,
                  });
                }
              });
            } else {
              possibilities_rendered.push({
                code: possibility.code,
                element_modif_code: element_modifie.code,
                img_path: possibility.img_scene_path,
              });
            }
          }
        });
      }
    });
  });
  return {
    img_root_path: img_root_path,
    possibilities_rendered: possibilities_rendered,
  };
}

export function nextScene(configuration, choice, usefulIndexes) {
  return (dispatch) => {
    let newSceneIndex = usefulIndexes.scene_index + 1;
    // while (
    //   configuration.classifications_scenes[
    //     usefulIndexes.classification_scene_index
    //   ].scenes[usefulIndexes.scene_index].name ===
    //   configuration.classifications_scenes[
    //     usefulIndexes.classification_scene_index
    //   ].scenes[newSceneIndex].name
    // ) {
    //   newSceneIndex += 1;
    // }
    while (choice.disabledScenesIndexes.includes(newSceneIndex)) {
      newSceneIndex += 1;
    }
    let newClassificationSceneIndex = usefulIndexes.classification_scene_index;
    if (
      configuration.classifications_scenes[
        usefulIndexes.classification_scene_index
      ].scenes[newSceneIndex] === undefined
    ) {
      newSceneIndex = 0;
      while (choice.disabledScenesIndexes.includes(newSceneIndex)) {
        newSceneIndex += 1;
      }
      newClassificationSceneIndex++;
      dispatch(setClassificationSceneIndex(newClassificationSceneIndex));
      if (newSceneIndex > 0) {
        dispatch(setSceneIndex(newSceneIndex));
      }
    } else {
      dispatch(setSceneIndex(newSceneIndex));
    }
    dispatch(
      navigate({
        code_classification:
          configuration.classifications_scenes[newClassificationSceneIndex]
            .code,
        code_scene:
          configuration.classifications_scenes[newClassificationSceneIndex]
            .scenes[newSceneIndex].code,
      })
    );
    dispatch(
      initScene(
        getNextOrPreviousScene(
          configuration,
          choice,
          newClassificationSceneIndex,
          newSceneIndex
        )
      )
    );
  };
}

export function previousScene(configuration, choice, usefulIndexes) {
  return (dispatch) => {
    let newSceneIndex = usefulIndexes.scene_index - 1;
    while (choice.disabledScenesIndexes.includes(newSceneIndex)) {
      newSceneIndex -= 1;
    }
    let newClassificationSceneIndex = usefulIndexes.classification_scene_index;
    if (
      configuration.classifications_scenes[
        usefulIndexes.classification_scene_index
      ].scenes[newSceneIndex] === undefined
    ) {
      newClassificationSceneIndex--;
      newSceneIndex =
        configuration.classifications_scenes[newClassificationSceneIndex].scenes
          .length - 1;
      dispatch(setClassificationSceneIndex(newClassificationSceneIndex));
      dispatch(setSceneIndex(newSceneIndex));
    } else {
      dispatch(setSceneIndex(newSceneIndex));
    }
    let scene = getNextOrPreviousScene(
      configuration,
      choice,
      newClassificationSceneIndex,
      newSceneIndex
    );
    dispatch(
      navigate({
        code_classification:
          configuration.classifications_scenes[newClassificationSceneIndex]
            .code,
        code_scene:
          configuration.classifications_scenes[newClassificationSceneIndex]
            .scenes[newSceneIndex].code,
      })
    );
    dispatch(initScene(scene));
  };
}

export function goToClassificationScene(
  configuration,
  choice,
  classification_scene,
  index
) {
  return (dispatch) => {
    dispatch(setClassificationSceneIndex(index));
    dispatch(setSceneIndex(0));
    let scene = getNextOrPreviousScene(configuration, choice, index, 0);
    dispatch(
      navigate({
        code_classification: configuration.classifications_scenes[index].code,
        code_scene: configuration.classifications_scenes[index].scenes[0].code,
      })
    );
    dispatch(initScene(scene));
  };
}

export function goToScene(configuration, choice, sceneIndex) {
  return (dispatch) => {
    dispatch(setSceneIndex(sceneIndex));
    let scene = getNextOrPreviousScene(configuration, choice, 1, sceneIndex);
    dispatch(
      navigate({
        code_classification: configuration.classifications_scenes[1].code,
        code_scene:
          configuration.classifications_scenes[1].scenes[sceneIndex].code,
      })
    );
    dispatch(initScene(scene));
  };
}
