import React from 'react';
import '../styles/components/Pose.css';
import { modifyScene } from '../redux/actions/actionCreators';
import { connect } from 'react-redux';

const Pose = (props) => {
  function handleClick(possibility_code, pose_code, img_path) {
    props.dispatch(
      modifyScene(
        props.navigation,
        props.scene,
        props.choice,
        props.type_modification_code,
        possibility_code,
        pose_code,
        props.element_modif_code,
        img_path
      )
    );
  }

  if (props.possibility.poses !== undefined) {
    return (
      <div
        className={
          props.choice.classifications_scenes[
            props.usefulIndexes.classification_scene_index
          ].scenes[props.usefulIndexes.scene_index].elements_modifies[
            props.element_modif_index
          ].types_modifications.possibilities.code === props.possibility.code
            ? 'pose-poses-active'
            : 'pose-poses'
        }
      >
        <p className='pose-h3'>Type de pose :</p>
        {props.possibility.poses.map((pose, index) => {
          if (
            props.choice.classifications_scenes[
              props.usefulIndexes.classification_scene_index
            ].scenes[props.usefulIndexes.scene_index].elements_modifies[
              props.element_modif_index
            ].types_modifications.possibilities.poses !== undefined
          ) {
            return (
              <div
                key={index}
                className={
                  props.choice.classifications_scenes[
                    props.usefulIndexes.classification_scene_index
                  ].scenes[props.usefulIndexes.scene_index].elements_modifies[
                    props.element_modif_index
                  ].types_modifications.possibilities.poses.code === pose.code
                    ? 'pose-option pose-option-active'
                    : 'pose-option pose-option-inactive'
                }
                onClick={() =>
                  handleClick(
                    props.possibility.code,
                    pose.code,
                    pose.img_scene_path
                  )
                }
              >
                <p className='pose-option-txt'>{pose.name}</p>
                <div
                  className='pose-option-img'
                  style={{
                    backgroundImage:
                      'url(' +
                      require('../assets/images/' + pose.img_miniature_path) +
                      ')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
              </div>
            );
          } else {
            return <div key={index}></div>;
          }
        })}
      </div>
    );
  } else {
    return <div></div>;
  }
};

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    scene: state.scene,
    choice: state.choice,
    usefulIndexes: state.usefulIndexes,
  };
}

export default connect(mapStateToProps)(Pose);
