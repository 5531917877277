import { NAVIGATE } from './types';

// ACTION CREATORS

export function navigate(data) {
  return {
    type: NAVIGATE,
    payload: data,
  };
}
