import React, { useState, useEffect } from 'react';
import '../styles/pages/NewProject.css';
import logo from '../assets/images/Logo.png';
import { Link } from 'react-router-dom';
import {
  modifyProjectChoice,
  modifyProjectInfo,
} from '../redux/actions/userActions';
import { connect } from 'react-redux';
import configuration from '../script/output.json';
import configuration_safari from '../script/safari/output.json';
import { setUpConfiguration } from '../redux/actions/configurationActions';
import { SavePopup } from '../components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';

const ModifyProjectInfo = (props) => {
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [pdv, setPdv] = useState(
    props.currentuser.profil == 1
      ? props.currentuser.pdv
      : props.currentuser.projects[props.usefulIndexes.project_index].pdv
  );

  const [initialProjectName, setInitialProjectName] = useState();
  const [projectName, setProjectName] = useState();
  const [initialType, setInitialType] = useState();
  const [initInfo, setInitInfo] = useState({
    informationsGenerales: {
      name: 'INFORMATION GÉNÉRALES',
      values: {
        nomDuPointDeVente: {
          name: 'Nom du point de vente',
          value: null,
        },
        typeDePointDeVente: {
          name: 'Type de point de vente',
          value: null,
        },
        date: {
          name: 'Date',
          value: null,
        },
        numeroDuPointDeVente: {
          name: 'Numéro du point de vente',
          value: props.currentuser.profil == 1 ? props.currentuser.pdv : null,
        },
      },
    },
    relatifAuPointDeVente: {
      name: 'RELATIF AU POINT DE VENTE',
      values: {
        chiffreDAffaireANmoins1: {
          name: "Chiffre d'affaires à N-1",
          value: null,
        },
        chiffreDAffaireAN: {
          name: "Chiffre d'affaires à N",
          value: null,
        },
        echeanceProjet: {
          name: 'Échéance projet',
          value: null,
        },
        vocationPointDeVenteApresLeProjet: {
          name: 'Spécificités propres au point de vente Fabmag',
          value: null,
        },
        natureDuProjet: {
          name: 'Nature du projet',
          value: [],
        },
        autreNatureDuProjet: {
          name: "Si autres, merci d'expliquer la nature du projet",
          value: null,
        },
      },
    },
  });

  const [date, setDate] = useState(null);
  const [echeanceDate, setEcheanceDate] = useState(null);

  const [type, setType] = useState();
  const [info, setInfo] = useState({
    informationsGenerales: {
      name: 'INFORMATION GÉNÉRALES',
      values: {
        nomDuPointDeVente: {
          name: 'Nom du point de vente',
          value: null,
        },
        typeDePointDeVente: {
          name: 'Type de point de vente',
          value: null,
        },
        date: {
          name: 'Date',
          value: null,
        },
        numeroDuPointDeVente: {
          name: 'Numéro du point de vente',
          value: props.currentuser.profil == 1 ? props.currentuser.pdv : null,
        },
      },
    },
    relatifAuPointDeVente: {
      name: 'RELATIF AU POINT DE VENTE',
      values: {
        chiffreDAffaireANmoins1: {
          name: "Chiffre d'affaires à N-1",
          value: null,
        },
        chiffreDAffaireAN: {
          name: "Chiffre d'affaires à N",
          value: null,
        },
        echeanceProjet: {
          name: 'Échéance projet',
          value: null,
        },
        vocationPointDeVenteApresLeProjet: {
          name: 'Spécificités propres au point de vente Fabmag',
          value: null,
        },
        natureDuProjet: {
          name: 'Nature du projet',
          value: [],
        },
        autreNatureDuProjet: {
          name: "Si autres, merci d'expliquer la nature du projet",
          value: null,
        },
      },
    },
  });

  const [nomDuPointDeVenteInvalid, setNomDuPointDeVenteInvalid] = useState(
    false
  );

  const [
    numeroDuPointDeVenteInvalid,
    setNumeroDuPointDeVenteInvalid,
  ] = useState(false);

  const [dateInvalid, setDateInvalid] = useState(false);

  const [
    chiffreDAffairesANmoins1Invalid,
    setChiffreDAffairesANmoins1Invalid,
  ] = useState(false);

  const [chiffreDAffairesANInvalid, setChiffreDAffairesANInvalid] = useState(
    false
  );

  useEffect(() => {
    let container = document.getElementsByClassName('np-header')[0];
    container.scrollIntoView();

    props.user.info.projects.map((project, index) => {
      if (props.location.search.replace('?', '') === project.project_name) {
        setUpConfiguration(
          props.user.info.projects[index].classifications_scenes,
          props.user.info.projects[
            index
          ].project_info.informationsGenerales.values.typeDePointDeVente.value.toUpperCase()
        );
        document
          .getElementById(
            'np-radio-subcontainer-' +
              props.user.info.projects[
                index
              ].project_info.informationsGenerales.values.typeDePointDeVente.value.toLowerCase()
          )
          .classList.add('np-radio-subcontainer-active');
        document.getElementById(
          'np-radio-' +
            props.user.info.projects[
              index
            ].project_info.informationsGenerales.values.typeDePointDeVente.value.toLowerCase()
        ).checked = true;
        document
          .getElementById(
            'np-radio-label-' +
              props.user.info.projects[
                index
              ].project_info.informationsGenerales.values.typeDePointDeVente.value.toLowerCase()
          )
          .classList.add('np-radio-label-active');

        setInitialProjectName(props.location.search.replace('?', ''));
        setProjectName(props.location.search.replace('?', ''));
        setInitialType(
          props.user.info.projects[index].project_info.informationsGenerales
            .values.typeDePointDeVente.value
        );
        setInitInfo(props.user.info.projects[index].project_info);
        props.user.info.projects[index].project_info[
          'relatifAuPointDeVente'
        ].values['natureDuProjet'].value.map((selection) => {
          let labels = document.getElementsByClassName(
            'np-checkbox-label-default'
          );
          for (var i = 0; i < labels.length; i++) {
            if (
              labels[i].textContent === selection &&
              document.getElementById('ndp-el' + (i + 1)) !== null
            ) {
              checkedCheckboxes.push(i + 1);
              document.getElementById('ndp-el' + (i + 1)).checked = true;
            }
          }
        });
        setDate(
          props.user.info.projects[index].project_info.informationsGenerales
            .values.date.value
        );
        setEcheanceDate(
          props.user.info.projects[index].project_info.relatifAuPointDeVente
            .values.echeanceProjet.value
        );
        setType(
          props.user.info.projects[index].project_info.informationsGenerales
            .values.typeDePointDeVente.value
        );
        setInfo(props.user.info.projects[index].project_info);
      }
    });

    if (document.getElementById('ndp-el10').checked === false) {
      document.getElementById('ndp-autres').disabled = true;
    }
  }, []);

  function handleProjectNameChange(e) {
    setNomDuPointDeVenteInvalid(false);
    let newInfo = { ...info };
    newInfo['informationsGenerales'].values['nomDuPointDeVente'].value =
      e.target.value;
    setInfo(newInfo);
    setProjectName(e.target.value);
  }

  function handleRadioTypeClick(t) {
    // Detect Chrome
    let chromeAgent = navigator.userAgent.indexOf('Chrome') > -1;
    // Detect Safari
    let safariAgent = navigator.userAgent.indexOf('Safari') > -1;

    // Discard Safari since it also matches Chrome
    if (chromeAgent && safariAgent) safariAgent = false;

    let newInfo = { ...info };
    if (t === 'proxi-urbaine' && t !== type) {
      setType('Proxi-Urbaine');
      newInfo['informationsGenerales'].values['typeDePointDeVente'].value =
        'Proxi-Urbaine';

      document
        .getElementById('np-radio-subcontainer-proxi-urbaine')
        .classList.add('np-radio-subcontainer-active');
      document
        .getElementById('np-radio-subcontainer-contact')
        .classList.remove('np-radio-subcontainer-active');
      document
        .getElementById('np-radio-subcontainer-super')
        .classList.remove('np-radio-subcontainer-active');
      document
        .getElementById('np-radio-subcontainer-hyper')
        .classList.remove('np-radio-subcontainer-active');

      document.getElementById('np-radio-proxi-urbaine').checked = true;
      document.getElementById('np-radio-contact').checked = false;
      document.getElementById('np-radio-super').checked = false;
      document.getElementById('np-radio-hyper').checked = false;

      document
        .getElementById('np-radio-label-proxi-urbaine')
        .classList.add('np-radio-label-active');
      document
        .getElementById('np-radio-label-contact')
        .classList.remove('np-radio-label-active');
      document
        .getElementById('np-radio-label-super')
        .classList.remove('np-radio-label-active');
      document
        .getElementById('np-radio-label-hyper')
        .classList.remove('np-radio-label-active');

      if (!safariAgent) {
        props.dispatch(setUpConfiguration(configuration, 'PROXI-URBAINE'));
      } else {
        props.dispatch(
          setUpConfiguration(configuration_safari, 'PROXI-URBAINE')
        );
      }
    } else if (t === 'contact' && t !== type) {
      setType('Contact');
      newInfo['informationsGenerales'].values['typeDePointDeVente'].value =
        'Contact';

      document
        .getElementById('np-radio-subcontainer-proxi-urbaine')
        .classList.remove('np-radio-subcontainer-active');
      document
        .getElementById('np-radio-subcontainer-contact')
        .classList.add('np-radio-subcontainer-active');
      document
        .getElementById('np-radio-subcontainer-super')
        .classList.remove('np-radio-subcontainer-active');
      document
        .getElementById('np-radio-subcontainer-hyper')
        .classList.remove('np-radio-subcontainer-active');

      document.getElementById('np-radio-proxi-urbaine').checked = false;
      document.getElementById('np-radio-contact').checked = true;
      document.getElementById('np-radio-super').checked = false;
      document.getElementById('np-radio-hyper').checked = false;

      document
        .getElementById('np-radio-label-proxi-urbaine')
        .classList.remove('np-radio-label-active');
      document
        .getElementById('np-radio-label-contact')
        .classList.add('np-radio-label-active');
      document
        .getElementById('np-radio-label-super')
        .classList.remove('np-radio-label-active');
      document
        .getElementById('np-radio-label-hyper')
        .classList.remove('np-radio-label-active');

      if (!safariAgent) {
        props.dispatch(setUpConfiguration(configuration, 'CONTACT'));
      } else {
        props.dispatch(setUpConfiguration(configuration_safari, 'CONTACT'));
      }
    } else if (t === 'super' && t !== type) {
      setType('Super');
      newInfo['informationsGenerales'].values['typeDePointDeVente'].value =
        'Super';

      document
        .getElementById('np-radio-subcontainer-proxi-urbaine')
        .classList.remove('np-radio-subcontainer-active');
      document
        .getElementById('np-radio-subcontainer-contact')
        .classList.remove('np-radio-subcontainer-active');
      document
        .getElementById('np-radio-subcontainer-super')
        .classList.add('np-radio-subcontainer-active');
      document
        .getElementById('np-radio-subcontainer-hyper')
        .classList.remove('np-radio-subcontainer-active');

      document.getElementById('np-radio-proxi-urbaine').checked = false;
      document.getElementById('np-radio-contact').checked = false;
      document.getElementById('np-radio-super').checked = true;
      document.getElementById('np-radio-hyper').checked = false;

      document
        .getElementById('np-radio-label-proxi-urbaine')
        .classList.remove('np-radio-label-active');
      document
        .getElementById('np-radio-label-contact')
        .classList.remove('np-radio-label-active');
      document
        .getElementById('np-radio-label-super')
        .classList.add('np-radio-label-active');
      document
        .getElementById('np-radio-label-hyper')
        .classList.remove('np-radio-label-active');

      if (!safariAgent) {
        props.dispatch(setUpConfiguration(configuration, 'SUPER'));
      } else {
        props.dispatch(setUpConfiguration(configuration_safari, 'SUPER'));
      }
    } else if (t === 'hyper' && t !== type) {
      setType('Hyper');
      newInfo['informationsGenerales'].values['typeDePointDeVente'].value =
        'Hyper';

      document
        .getElementById('np-radio-subcontainer-proxi-urbaine')
        .classList.remove('np-radio-subcontainer-active');
      document
        .getElementById('np-radio-subcontainer-contact')
        .classList.remove('np-radio-subcontainer-active');
      document
        .getElementById('np-radio-subcontainer-super')
        .classList.remove('np-radio-subcontainer-active');
      document
        .getElementById('np-radio-subcontainer-hyper')
        .classList.add('np-radio-subcontainer-active');

      document.getElementById('np-radio-proxi-urbaine').checked = false;
      document.getElementById('np-radio-contact').checked = false;
      document.getElementById('np-radio-super').checked = false;
      document.getElementById('np-radio-hyper').checked = true;

      document
        .getElementById('np-radio-label-proxi-urbaine')
        .classList.remove('np-radio-label-active');
      document
        .getElementById('np-radio-label-contact')
        .classList.remove('np-radio-label-active');
      document
        .getElementById('np-radio-label-super')
        .classList.remove('np-radio-label-active');
      document
        .getElementById('np-radio-label-hyper')
        .classList.add('np-radio-label-active');

      if (!safariAgent) {
        props.dispatch(setUpConfiguration(configuration, 'HYPER'));
      } else {
        props.dispatch(setUpConfiguration(configuration_safari, 'HYPER'));
      }
    }
    setInfo(newInfo);
    setType(t);
  }

  function handleCheckboxClick(e, index) {
    let newCheckedCheckboxes = [...checkedCheckboxes];
    let el = document.getElementById('ndp-el' + index);
    if (
      e.target.classList[0] === 'np-checkbox-container' ||
      e.target.classList[0] === 'np-checkbox-container-active'
    ) {
      el.checked = !el.checked;
      el.checked
        ? newCheckedCheckboxes.push(index)
        : newCheckedCheckboxes.splice(checkedCheckboxes.indexOf(index), 1);
      setCheckedCheckboxes(newCheckedCheckboxes);
    } else {
      if (e.target.checked !== undefined) {
        e.target.checked
          ? newCheckedCheckboxes.push(index)
          : newCheckedCheckboxes.splice(checkedCheckboxes.indexOf(index), 1);
        setCheckedCheckboxes(newCheckedCheckboxes);
      }
    }
    updateNatureDuProjet(index);
  }

  function updateNatureDuProjet(index) {
    let labels = document.getElementsByTagName('label');
    for (var i = 0; i < labels.length; i++) {
      if (labels[i].htmlFor == 'ndp-el' + index) {
        let newInfo = { ...info };
        newInfo['relatifAuPointDeVente'].values['autreNatureDuProjet'] = {
          name: "Si autres, merci d'expliquer la nature du projet",
          value: null,
        };
        let newNatureDuProjet = [
          ...newInfo['relatifAuPointDeVente'].values['natureDuProjet'].value,
        ];
        if (newNatureDuProjet.includes(labels[i].textContent)) {
          newNatureDuProjet = newNatureDuProjet.filter(
            (element) => element !== labels[i].textContent
          );
          if (index === 10) {
            document.getElementById('ndp-autres').disabled = true;
            newInfo['relatifAuPointDeVente'].values[
              'autreNatureDuProjet'
            ].value = null;
            document.getElementById('ndp-autres').value = null;
          }
        } else {
          newNatureDuProjet.push(labels[i].textContent);
          if (index === 10) {
            document.getElementById('ndp-autres').disabled = false;
          }
        }
        newInfo['relatifAuPointDeVente'].values[
          'natureDuProjet'
        ].value = newNatureDuProjet;
        setInfo(newInfo);
        break;
      }
    }
  }

  function updateAutreNatureDuProjet(e) {
    let newInfo = { ...info };
    newInfo['relatifAuPointDeVente'].values['autreNatureDuProjet'].value =
      e.target.value;
    setInfo(newInfo);
  }

  function handleSubmitClick() {
    let requiredFields = document
      .getElementById('np-form')
      .querySelectorAll('[required]');
    requiredFields.forEach((requiredField, index) => {
      requiredField.classList.add('required');
    });
  }

  function handleNewProject(e) {
    e.preventDefault();

    setSavePopupOpened(true);
    if (type === initialType) {
      props.dispatch(
        modifyProjectInfo(initialProjectName, projectName, pdv, info)
      );
    } else {
      props.dispatch(
        modifyProjectChoice(
          initialProjectName,
          projectName,
          pdv,
          info,
          props.configuration
        )
      );
    }
  }

  function updateDate(e) {
    var date = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var dateStr = month + '/' + date + '/' + year;

    setDateInvalid(false);
    let newInfo = { ...info };
    newInfo['informationsGenerales'].values['date'].value = dateStr;
    setInfo(newInfo);
    setDate(dateStr);
  }

  function updateNumeroDePointDeVente(pdv) {
    setNumeroDuPointDeVenteInvalid(false);
    let newInfo = { ...info };
    newInfo['informationsGenerales'].values['numeroDuPointDeVente'].value =
      pdv.value;
    setInfo(newInfo);
    setPdv(pdv.value);
  }

  function updateChiffreDAffairesANmoins1(e) {
    setChiffreDAffairesANmoins1Invalid(false);
    let newInfo = { ...info };
    newInfo['relatifAuPointDeVente'].values['chiffreDAffaireANmoins1'].value =
      e.target.value;
    setInfo(newInfo);
  }

  function updateChiffreDAffairesAN(e) {
    setChiffreDAffairesANInvalid(false);
    let newInfo = { ...info };
    newInfo['relatifAuPointDeVente'].values['chiffreDAffaireAN'].value =
      e.target.value;
    setInfo(newInfo);
  }

  function updateEcheanceProjet(e) {
    var date = e.getDate();
    var month = e.getMonth() + 1;
    var year = e.getFullYear();

    var dateStr = month + '/' + date + '/' + year;

    let newInfo = { ...info };
    newInfo['relatifAuPointDeVente'].values['echeanceProjet'].value = dateStr;
    setInfo(newInfo);
    setEcheanceDate(dateStr);
  }

  function updateVocationPointDeVenteApresLeProjet(e) {
    let newInfo = { ...info };
    newInfo['relatifAuPointDeVente'].values[
      'vocationPointDeVenteApresLeProjet'
    ].value = e.target.value;
    setInfo(newInfo);
  }

  const [savePopupOpened, setSavePopupOpened] = useState(false);

  function closeSavePopup() {
    setSavePopupOpened(false);
  }

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: 15,
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        // border: state.isFocused ? 0 : 0,
      },
      cursor: 'text',
      boxsizing: 'border-box',
      height: 60,
      width: 443,
      borderWidth: 2,
      borderStyle: 'solid',
      borderRadius: 9,
      backgroundColor: state.isFocused ? '#ffffff' : '#ffffff',
      fontFamily: 'INTERMARCHE-Regular',
      fontSize: 18,
      letterSpacing: 0,
      outline: 'none',
      borderColor: numeroDuPointDeVenteInvalid ? '#ff6d00' : 'black',
    }),
    dropdownIndicator: () => ({
      cursor: 'pointer',
      marginLeft: 10,
      marginRight: 10,
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      paddingLeft: 15,
      fontFamily: 'INTERMARCHE-Regular',
      backgroundColor: isFocused ? 'rgba(0,0,0,0.16)' : null,
      color: 'black',
      cursor: 'pointer',
    }),
  };

  function pdvbuilder() {
    return props.currentuser.profil == 1 ? (
      <div className='np-input-container'>
        <label className='np-form-input-text' htmlFor='pdv-numero'>
          Numéro de point de vente :
        </label>
        <input
          required
          type='text'
          className='np-input'
          name='pdv-numero'
          value={props.currentuser.pdv}
          placeholder='XXX XXX XXX XXX'
          disabled
          onInvalid={(e) => {
            e.preventDefault();
            e.target.scrollIntoView();
            setNumeroDuPointDeVenteInvalid(true);
          }}
        />
        <p
          className='np-input-error-msg'
          style={
            numeroDuPointDeVenteInvalid
              ? { display: 'block' }
              : { display: 'none' }
          }>
          Veuillez renseigner ce champs
        </p>
      </div>
    ) : (
      <div className='np-input-container'>
        <div className='np-input-container'>
          <label className='np-form-input-text' htmlFor='pdv-name'>
            Numéro de point de vente :
          </label>
          <Select
            placeholder='Chercher un numéro de point de vente'
            defaultValue={props.currentuser.pdvs.filter(
              (npdv) => npdv.value === pdv
            )}
            options={props.currentuser.pdvs}
            styles={customStyles}
            onChange={(e) => {
              updateNumeroDePointDeVente(e);
            }}
          />
          <input
            className='np-input'
            style={{
              zIndex: -1,
              width: 443,
              opacity: 0,
              height: 60,
              position: 'absolute',
            }}
            value={pdv}
            required
            onInvalid={(e) => {
              e.preventDefault();
              e.target.scrollIntoView();
              setNumeroDuPointDeVenteInvalid(true);
            }}
          />
          <p
            className='np-input-error-msg'
            style={
              numeroDuPointDeVenteInvalid
                ? { display: 'block' }
                : { display: 'none' }
            }>
            Veuillez renseigner ce champs
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className='np-container'>
      <SavePopup
        whereFrom={'ModifyProjectInfo'}
        open={savePopupOpened}
        onClose={closeSavePopup}
      />
      <div className='np-header'>
        <Link className='np-header-logo' to='/accueil'>
          <img
            className='np-header-logo-img'
            src={logo}
            width='101'
            height='120'
          />
        </Link>
        <div>
          <h1 className='np-header-title'>
            MON APPLI <br />
            <span>MISE EN PROJET</span> <br />
            FAB'MAG
          </h1>
        </div>
        <div className='np-header-main'>
          <p className='np-header-heading'>
            {'MODIFICATION DU PROJET ' + props.location.search.replace('?', '')}
          </p>
          <p className='np-header-subheading'>
            FORMULAIRE D'INTRODUCTION À L'OUTIL DE CONCEPTION
          </p>
        </div>
      </div>
      <div>
        <form id='np-form' onSubmit={(e) => handleNewProject(e)}>
          <div className='np-form-container'>
            <div className='np-element'>
              <p className='np-element-title'>INFORMATIONS GÉNÉRALES</p>
              <div className='np-input-container'>
                <label className='np-form-input-text' htmlFor='pdv-name'>
                  Nom du point de vente :
                </label>
                <input
                  required
                  type='text'
                  className='np-input'
                  name='pdv-name'
                  placeholder='Nom du point de vente'
                  defaultValue={
                    initInfo.informationsGenerales.values.nomDuPointDeVente
                      .value
                  }
                  onChange={(e) => handleProjectNameChange(e)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  onInvalid={(e) => {
                    e.preventDefault();
                    e.target.scrollIntoView();
                    setNomDuPointDeVenteInvalid(true);
                  }}
                />
                <p
                  className='np-input-error-msg'
                  style={
                    nomDuPointDeVenteInvalid
                      ? { display: 'block' }
                      : { display: 'none' }
                  }>
                  Veuillez renseigner ce champs
                </p>
              </div>
              <div className='np-radio-title-container'>
                <p className='np-radio-title-text'>Type de point de vente :</p>
                <div className='np-radio-container'>
                  <div
                    id='np-radio-subcontainer-proxi-urbaine'
                    className='np-radio-subcontainer np-radio-margin-right np-radio-margin-bottom'
                    onClick={() => handleRadioTypeClick('proxi-urbaine')}>
                    <input
                      id='np-radio-proxi-urbaine'
                      className='np-radio'
                      type='radio'
                      name='pdv-type'
                      value='proxi-urbaine'
                    />
                    <label
                      id='np-radio-label-proxi-urbaine'
                      className='np-radio-label'
                      htmlFor='proxi-urbaine'>
                      Proxi-Urbaine
                    </label>
                  </div>
                  <div
                    id='np-radio-subcontainer-contact'
                    className='np-radio-subcontainer np-radio-margin-bottom'
                    onClick={() => handleRadioTypeClick('contact')}>
                    <input
                      id='np-radio-contact'
                      className='np-radio'
                      type='radio'
                      name='pdv-type'
                      value='contact'
                    />
                    <label
                      id='np-radio-label-contact'
                      className='np-radio-label'
                      htmlFor='contact'>
                      Contact
                    </label>
                  </div>
                  <div
                    id='np-radio-subcontainer-super'
                    className='np-radio-subcontainer np-radio-margin-right'
                    onClick={() => handleRadioTypeClick('super')}>
                    <input
                      id='np-radio-super'
                      className='np-radio'
                      type='radio'
                      name='pdv-type'
                      value='super'
                    />
                    <label
                      id='np-radio-label-super'
                      className='np-radio-label'
                      htmlFor='super'>
                      Super
                    </label>
                  </div>
                  <div
                    id='np-radio-subcontainer-hyper'
                    className='np-radio-subcontainer'
                    onClick={() => handleRadioTypeClick('hyper')}>
                    <input
                      id='np-radio-hyper'
                      className='np-radio'
                      type='radio'
                      name='pdv-type'
                      value='hyper'
                    />
                    <label
                      id='np-radio-label-hyper'
                      className='np-radio-label'
                      htmlFor='hyper'>
                      Hyper
                    </label>
                  </div>
                </div>
              </div>
              <div className='np-input-container'>
                <label className='np-form-input-text' htmlFor='date'>
                  Date
                </label>
                <DatePicker
                  required
                  dateFormat='dd/MM/yyyy'
                  className='np-input'
                  selected={date === null ? null : new Date(date)}
                  placeholderText='dd/mm/yyyy'
                  onChange={(date) => (date !== null ? updateDate(date) : null)}
                  onInvalid={(e) => {
                    e.preventDefault();
                    e.target.scrollIntoView();
                    setDateInvalid(true);
                  }}
                />
                <p
                  className='np-input-error-msg'
                  style={
                    dateInvalid ? { display: 'block' } : { display: 'none' }
                  }>
                  Veuillez renseigner ce champs
                </p>
              </div>
              {pdvbuilder()}
            </div>
            <div className='np-element'>
              <p className='np-element-title'>RELATIF AU POINT DE VENTE</p>
              <div className='np-input-container'>
                <label className='np-form-input-text' htmlFor='cda-1'>
                  Chiffre d'affaires à N-1 :
                </label>
                <input
                  onChange={(e) => {
                    updateChiffreDAffairesANmoins1(e);
                  }}
                  style={
                    chiffreDAffairesANmoins1Invalid
                      ? { borderColor: '#ff6d00' }
                      : { borderColor: 'black' }
                  }
                  type='text'
                  className='np-input'
                  name='cda-1'
                  placeholder='0 000€'
                  defaultValue={
                    initInfo.relatifAuPointDeVente.values
                      .chiffreDAffaireANmoins1.value
                  }
                  pattern='[0-9]+'
                  onInvalid={(e) => {
                    e.preventDefault();
                    e.target.scrollIntoView();
                    setChiffreDAffairesANmoins1Invalid(true);
                  }}
                />
                <p
                  className='np-input-error-msg'
                  style={
                    chiffreDAffairesANmoins1Invalid
                      ? { display: 'block' }
                      : { display: 'none' }
                  }>
                  Veuillez respecter le format requis
                </p>
              </div>
              <div className='np-input-container'>
                <label className='np-form-input-text' htmlFor='cda-2'>
                  Chiffre d'affaires à N :
                </label>
                <input
                  onChange={(e) => {
                    updateChiffreDAffairesAN(e);
                  }}
                  style={
                    chiffreDAffairesANInvalid
                      ? { borderColor: '#ff6d00' }
                      : { borderColor: 'black' }
                  }
                  type='text'
                  className='np-input'
                  name='cda-2'
                  placeholder='0 000€'
                  defaultValue={
                    initInfo.relatifAuPointDeVente.values.chiffreDAffaireAN
                      .value
                  }
                  pattern='[0-9]+'
                  onInvalid={(e) => {
                    e.preventDefault();
                    e.target.scrollIntoView();
                    setChiffreDAffairesANInvalid(true);
                  }}
                />
                <p
                  className='np-input-error-msg'
                  style={
                    chiffreDAffairesANInvalid
                      ? { display: 'block' }
                      : { display: 'none' }
                  }>
                  Veuillez respecter le format requis
                </p>
              </div>
              <div className='np-input-container'>
                <label className='np-form-input-text' htmlFor='ep-txt'>
                  Date ouverture Fabmag :
                </label>
                <DatePicker
                  dateFormat='dd/MM/yyyy'
                  className='np-input'
                  selected={
                    echeanceDate === null ? null : new Date(echeanceDate)
                  }
                  placeholderText='dd/mm/yyyy'
                  onChange={(date) =>
                    date !== null ? updateEcheanceProjet(date) : null
                  }
                />
              </div>
              <div className='np-input-container'>
                <label className='np-form-input-text' htmlFor='vpdv-txt2'>
                  Spécificités propres au point de vente Fabmag :
                </label>
                <textarea
                  onChange={(e) => {
                    updateVocationPointDeVenteApresLeProjet(e);
                  }}
                  type='text'
                  className='np-input np-big-input'
                  name='vpdv-txt2'
                  placeholder='Texte...'
                  defaultValue={
                    initInfo.relatifAuPointDeVente.values
                      .vocationPointDeVenteApresLeProjet.value
                  }
                />
              </div>
              <div className='np-input-container'>
                <p className='np-radio-title-text'>Nature du projet :</p>
                <div className='np-checkboxes-container'>
                  <div className='np-checkboxes-subcontainer'>
                    <div
                      className={
                        checkedCheckboxes.includes(1)
                          ? 'np-checkbox-container-active'
                          : 'np-checkbox-container'
                      }
                      onClick={(e) => handleCheckboxClick(e, 1)}>
                      <input
                        className='np-checkbox'
                        type='checkbox'
                        id='ndp-el1'
                        name='ndp-el1'
                      />
                      <label
                        htmlFor='ndp-el1'
                        className={
                          checkedCheckboxes.includes(1)
                            ? 'np-checkbox-label-default np-checkbox-label-active'
                            : 'np-checkbox-label-default np-checkbox-label'
                        }
                        onClick={(e) => handleCheckboxClick(e, 1)}>
                        Création
                      </label>
                    </div>
                    <div
                      className={
                        checkedCheckboxes.includes(2)
                          ? 'np-checkbox-container-active'
                          : 'np-checkbox-container'
                      }
                      onClick={(e) => handleCheckboxClick(e, 2)}>
                      <input
                        className='np-checkbox'
                        type='checkbox'
                        id='ndp-el2'
                        name='ndp-el2'
                      />
                      <label
                        htmlFor='ndp-el2'
                        className={
                          checkedCheckboxes.includes(2)
                            ? 'np-checkbox-label-default np-checkbox-label-active'
                            : 'np-checkbox-label-default np-checkbox-label'
                        }
                        onClick={(e) => handleCheckboxClick(e, 2)}>
                        Agrandissement
                      </label>
                    </div>
                    <div
                      className={
                        checkedCheckboxes.includes(3)
                          ? 'np-checkbox-container-active'
                          : 'np-checkbox-container'
                      }
                      onClick={(e) => handleCheckboxClick(e, 3)}>
                      <input
                        className='np-checkbox'
                        type='checkbox'
                        id='ndp-el3'
                        name='ndp-el3'
                      />
                      <label
                        htmlFor='ndp-el3'
                        className={
                          checkedCheckboxes.includes(3)
                            ? 'np-checkbox-label-default np-checkbox-label-active'
                            : 'np-checkbox-label-default np-checkbox-label'
                        }
                        onClick={(e) => handleCheckboxClick(e, 3)}>
                        Façade
                      </label>
                    </div>
                    <div
                      className={
                        checkedCheckboxes.includes(4)
                          ? 'np-checkbox-container-active'
                          : 'np-checkbox-container'
                      }
                      onClick={(e) => handleCheckboxClick(e, 4)}>
                      <input
                        className='np-checkbox'
                        type='checkbox'
                        id='ndp-el4'
                        name='ndp-el4'
                      />
                      <label
                        htmlFor='ndp-el4'
                        className={
                          checkedCheckboxes.includes(4)
                            ? 'np-checkbox-label-default np-checkbox-label-active'
                            : 'np-checkbox-label-default np-checkbox-label'
                        }
                        onClick={(e) => handleCheckboxClick(e, 4)}>
                        Boutique
                      </label>
                    </div>
                    <div
                      className={
                        checkedCheckboxes.includes(5)
                          ? 'np-checkbox-container-active'
                          : 'np-checkbox-container'
                      }
                      onClick={(e) => handleCheckboxClick(e, 5)}>
                      <input
                        className='np-checkbox'
                        type='checkbox'
                        id='ndp-el5'
                        name='ndp-el5'
                      />
                      <label
                        htmlFor='ndp-el5'
                        className={
                          checkedCheckboxes.includes(5)
                            ? 'np-checkbox-label-default np-checkbox-label-active'
                            : 'np-checkbox-label-default np-checkbox-label'
                        }
                        onClick={(e) => handleCheckboxClick(e, 5)}>
                        Drive
                      </label>
                    </div>
                  </div>
                  <div className='np-checkboxes-subcontainers-divider np-checkboxes-subcontainer'>
                    <div
                      className={
                        checkedCheckboxes.includes(6)
                          ? 'np-checkbox-container-active'
                          : 'np-checkbox-container'
                      }
                      onClick={(e) => handleCheckboxClick(e, 6)}>
                      <input
                        className='np-checkbox'
                        type='checkbox'
                        id='ndp-el6'
                        name='ndp-el6'
                      />
                      <label
                        htmlFor='ndp-el6'
                        className={
                          checkedCheckboxes.includes(6)
                            ? 'np-checkbox-label-default np-checkbox-label-active'
                            : 'np-checkbox-label-default np-checkbox-label'
                        }
                        onClick={(e) => handleCheckboxClick(e, 6)}>
                        Transfert
                      </label>
                    </div>
                    <div
                      className={
                        checkedCheckboxes.includes(7)
                          ? 'np-checkbox-container-active'
                          : 'np-checkbox-container'
                      }
                      onClick={(e) => handleCheckboxClick(e, 7)}>
                      <input
                        className='np-checkbox'
                        type='checkbox'
                        id='ndp-el7'
                        name='ndp-el7'
                      />
                      <label
                        htmlFor='ndp-el7'
                        className={
                          checkedCheckboxes.includes(7)
                            ? 'np-checkbox-label-default np-checkbox-label-active'
                            : 'np-checkbox-label-default np-checkbox-label'
                        }
                        onClick={(e) => handleCheckboxClick(e, 7)}>
                        Rénovation / mise aux couleurs
                      </label>
                    </div>
                    <div
                      className={
                        checkedCheckboxes.includes(8)
                          ? 'np-checkbox-container-active'
                          : 'np-checkbox-container'
                      }
                      onClick={(e) => handleCheckboxClick(e, 8)}>
                      <input
                        className='np-checkbox'
                        type='checkbox'
                        id='ndp-el8'
                        name='ndp-el8'
                      />
                      <label
                        htmlFor='ndp-el8'
                        className={
                          checkedCheckboxes.includes(8)
                            ? 'np-checkbox-label-default np-checkbox-label-active'
                            : 'np-checkbox-label-default np-checkbox-label'
                        }
                        onClick={(e) => handleCheckboxClick(e, 8)}>
                        Mail et Services
                      </label>
                    </div>
                    <div
                      className={
                        checkedCheckboxes.includes(9)
                          ? 'np-checkbox-container-active'
                          : 'np-checkbox-container'
                      }
                      onClick={(e) => handleCheckboxClick(e, 9)}>
                      <input
                        className='np-checkbox'
                        type='checkbox'
                        id='ndp-el9'
                        name='ndp-el9'
                      />
                      <label
                        htmlFor='ndp-el9'
                        className={
                          checkedCheckboxes.includes(9)
                            ? 'np-checkbox-label-default np-checkbox-label-active'
                            : 'np-checkbox-label-default np-checkbox-label'
                        }
                        onClick={(e) => handleCheckboxClick(e, 9)}>
                        Station et/ou Services
                      </label>
                    </div>
                    <div
                      className={
                        checkedCheckboxes.includes(10)
                          ? 'np-checkbox-container-active'
                          : 'np-checkbox-container'
                      }
                      onClick={(e) => handleCheckboxClick(e, 10)}>
                      <input
                        className='np-checkbox'
                        type='checkbox'
                        id='ndp-el10'
                        name='ndp-el10'
                      />
                      <label
                        htmlFor='ndp-el10'
                        className={
                          checkedCheckboxes.includes(10)
                            ? 'np-checkbox-label-default np-checkbox-label-active'
                            : 'np-checkbox-label-default np-checkbox-label'
                        }
                        onClick={(e) => handleCheckboxClick(e, 10)}>
                        Autres
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='np-input-container'>
                <label className='np-form-input-text' htmlFor='ndp-txt'>
                  Si autres, merci d'expliquer la nature du projet :
                </label>
                <textarea
                  id='ndp-autres'
                  onChange={(e) => {
                    updateAutreNatureDuProjet(e);
                  }}
                  type='text'
                  className='np-input np-big-input'
                  name='ndp-txt'
                  placeholder='Texte...'
                  defaultValue={
                    initInfo.relatifAuPointDeVente.values
                      .autreNatureDuProjet !== undefined
                      ? initInfo.relatifAuPointDeVente.values
                          .autreNatureDuProjet.value
                      : null
                  }
                />
              </div>
            </div>
            <input
              className='np-submit'
              type='submit'
              onClick={() => handleSubmitClick()}
              value='VALIDER'
            />
          </div>
        </form>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    configuration: state.configuration,
    user: state.user,
    currentuser: state.admin.loggeduser,
    usefulIndexes: state.usefulIndexes,
  };
}

export default connect(mapStateToProps)(ModifyProjectInfo);
