import {combineReducers} from 'redux';
import {configurationReducer} from './configurationReducer';
import {choiceReducer} from './choiceReducer';
import {sceneReducer} from './sceneReducer';
import {userReducer} from './userReducer';
import {navigationReducer} from './navigationReducer';
import {usefulIndexesReducer} from './usefulIndexesReducer';
import {adminReducer} from './adminReducer'

const rootReducer = combineReducers({
    configuration: configurationReducer,
    choice: choiceReducer,
    scene: sceneReducer,
    user: userReducer,
    navigation: navigationReducer,
    usefulIndexes: usefulIndexesReducer,
    admin: adminReducer
});

export default rootReducer;
