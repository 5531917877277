import React from 'react';
import './App.css';
import configuration from './script/output.json';
import configuration_safari from './script/safari/output.json';
import { setUpConfiguration } from './redux/actions/configurationActions';
import {
  Login,
  FirstMenu,
  NewProject,
  Project,
  Synthese,
  MyProjects,
  UserManagement,
  ResetPassword,
  ModifyProjectInfo,
  ModifyProjectInfoTab,
} from './pages';
import PrivateRoute from './components/auth/private-route';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import history from './history';

const App = (props) => {
  // Detect Chrome
  let chromeAgent = navigator.userAgent.indexOf('Chrome') > -1;
  // Detect Safari
  let safariAgent = navigator.userAgent.indexOf('Safari') > -1;
  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;
  if (!safariAgent) {
    props.dispatch(setUpConfiguration(configuration, 'PROXI-URBAINE'));
  } else {
    props.dispatch(setUpConfiguration(configuration_safari, 'PROXI-URBAINE'));
  }

  return (
    <div className='App'>
      <BrowserRouter history={history}>
        <Route exact path='/resetpassword/' component={ResetPassword} />
        <Route exact path='/' component={Login} />
        <Route exact path='/connexion' component={Login} />
        <PrivateRoute exact path='/accueil' component={FirstMenu} />
        <PrivateRoute exact path='/mesprojetsencours' component={MyProjects} />
        <PrivateRoute
          exact
          path='/edit-project'
          component={ModifyProjectInfo}
        />
        <PrivateRoute exact path='/creationprojet' component={NewProject} />
        <PrivateRoute exact path='/projet' component={Project} />
        <PrivateRoute
          exact
          path='/formulaire'
          component={ModifyProjectInfoTab}
        />
        <PrivateRoute exact path='/synthese' component={Synthese} />
        <PrivateRoute
          exact
          path='/gestionutilisateur'
          component={UserManagement}
        />

      </BrowserRouter>
    </div>
  );
};

export default connect()(App);
