import {
  ADD_PROJECT,
  MODIFY_PROJECT_INFO,
  MODIFY_PROJECT_CHOICE,
  UPDATE_PROJECT,
  REMOVE_PROJECT,
  LOAD_USER_INFO,
} from '../actions/types';
import user from '../tests/user.json';
const initialState = user;

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PROJECT:
      state.info.projects.push(action.payload);
      localStorage.setItem('loggeduser', JSON.stringify(state.info));
      return Object.assign({}, state, {
        ...state,
        projects: [...state.projects, action.payload],
      });
    case MODIFY_PROJECT_CHOICE:
      let projects = [...state.info.projects];
      projects = projects.map((project, index) => {
        if (project.project_name === action.payload.initialName) {
          project.project_name = action.payload.name;
          project.pdv = action.payload.pdv;
          project.project_info = action.payload.info;
          project.classifications_scenes =
            action.payload.classifications_scenes;
        }
        return project;
      });
      localStorage.setItem('loggeduser', JSON.stringify(state.info));
      return Object.assign({}, state, {
        ...state,
        choice: action.payload.classifications_scenes,
        info: {
          ...state.info,
          projects: projects,
        },
      });
    case MODIFY_PROJECT_INFO:
      let projects2 = [...state.info.projects];
      projects2 = projects2.map((project, index) => {
        if (project.project_name === action.payload.initialName) {
          project.project_name = action.payload.name;
          project.pdv = action.payload.pdv;
          project.project_info = action.payload.info;
        }
        return project;
      });
      localStorage.setItem('loggeduser', JSON.stringify(state.info));
      return Object.assign({}, state, {
        ...state,
        info: {
          ...state.info,
          projects: projects2,
        },
      });
    case UPDATE_PROJECT:
      console.log('STATE', state.info);
      let updatedProjects = [...state.info.projects];
      updatedProjects[action.payload.idx] =
        action.payload.projects[action.payload.idx];
      localStorage.setItem('loggeduser', JSON.stringify(state.info));
      return Object.assign({}, state, {
        ...state,
        info: {
          ...state.info,
          projects: updatedProjects,
        },
        success: action.payload.success,
      });
    case REMOVE_PROJECT:
      return Object.assign({}, state, {
        data: action.payload,
      });
    case LOAD_USER_INFO:
      return Object.assign({}, state, {
        info: action.payload,
      });
    default:
      return state;
  }
}
