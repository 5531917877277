import React, { useState, useEffect } from 'react';
import { ChangeScene, SubMainNav, ShowScene, RelativeNav } from './';
import '../styles/components/Scene.css';
import { connect } from 'react-redux';

const Scene = (props) => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  useEffect(() => {
    updateImageDimensions();
    window.addEventListener('resize', updateImageDimensions);
  }, []);

  const imgBaseWidth = 1920;
  const imgBaseHeight = 1080;

  function updateImageDimensions() {
    let left = document.querySelector('.cs-container');

    if (left !== null) {
      //this calculates the padding %
      let innerWidth = window.outerWidth - left.clientWidth;
      let innerHeight = window.outerHeight;
      const h = innerHeight - innerHeight * 0.1;
      const w = innerWidth - innerWidth * 0.1;

      let imgWidth = w;
      //calculates hight based on aspect ratio
      let imgHeight = (imgWidth * imgBaseHeight) / imgBaseWidth;

      //if height is greater than the inner container, set the maximun size and recalculate width base on max-height
      if (imgHeight > h) {
        imgHeight = h;
        imgWidth = (imgHeight * imgBaseWidth) / imgBaseHeight;
      }

      setWidth(imgWidth);
      setHeight(imgHeight);
    }
  }

  if (props.scene !== null) {
    return (
      <div className='scene-container'>
        <ChangeScene height={height} />
        <div className='scene-right'>
          <SubMainNav />
          <ShowScene width={width} height={height} />
          <RelativeNav />
        </div>
      </div>
    );
  } else {
    return <div className='cs-container'></div>;
  }
};

function mapStateToProps(state) {
  return {
    scene: state.scene,
  };
}

export default connect(mapStateToProps)(Scene);
