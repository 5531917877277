/**
 * Created by mnassih on 25/09/2020.
 */

import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Button} from "react-bootstrap";
import {createuser,updateuser} from '../../redux/actions/adminAction';
import {connect} from 'react-redux';

import Loader from 'react-loader-spinner'
import '../../styles/components/userinfo.css';
import { useForm } from "react-hook-form";

const UserForm = (props) => {


    const [loaded, setLoaded] = useState(true);
    const [userSelected, setUserSelected]=useState({});
    const [userFromTable, setUserFromTable]=useState({});
    const [userRole,setUserRole]=useState({role:props.userSelected.role})
    const [userProfil,setUserProfil]=useState({profil:(props.userSelected.profil)?props.userSelected.profil:'1'})
    const [showSubmitErrors,setShowSubmitErrors]=useState(false);
    const [submitErrors,setSubmitErrors]=useState('');

    const { register, handleSubmit, watch, errors } = useForm({
        defaultValues: props.userSelected});
    const onSubmit = async data => {
        if (props.isNew)
        {
            data.role=userRole.role
            data.profil=userProfil.profil
            delete(data.passwordconfirm);
            const reponse=await props.dispatch(createuser(data));

            console.log(reponse)
            if(reponse.status==200)
                props.closePopUP()
            else{
                setShowSubmitErrors(true);
                setSubmitErrors(reponse.message)
            }
        }

        else{
            data.role=userRole.role
            data.profil=userProfil.profil
            delete(data.passwordconfirm);
            const reponse=await props.dispatch(updateuser(data));

            console.log(reponse)
            if(reponse.nModified==1)
                props.closePopUP()
            else{
                setShowSubmitErrors(true);
                setSubmitErrors(reponse.message)
            }
        }

    }

    const submitErrorContainer=()=>{
        return (showSubmitErrors)?
            (<div className="ztogroupedrow">
                <Row className="popUpRow">
                    <Col className="popupItemPadding ztoItemLabel" xs="12">{submitErrors}</Col>
                </Row>
            </div>):
            (<div></div>)
    }


    const callApi = async() => {
        setLoaded(!loaded)
    }




    useEffect(() => {
        //  console.log("Selected = ",props.userSelected)
        //if (!loaded)
          //  callApi()
      //  setUserFromTable(props.userSelected)

    });


    const changeUserRole = () => {
        console.log("userRole ",userRole)
        if (userRole.role == 'user')
            setUserRole({ role: 'admin'})
        else
            setUserRole({ role: 'user'})

        console.log("CHANGE USER STATE", userRole)
    }

    const changeUserProfil = () => {
        console.log("userProfil ",userProfil)
        if (userProfil.profil == '2')
            setUserProfil({ profil: '1'})
        else
            setUserProfil({ profil: '2'})

        console.log("CHANGE USER STATE", userProfil)
    }


    const closeModal = () => {
        props.closePopUP()
    }

    const handleChange = (event) => {
        props.userSelected={...props.userSelected,familyName: event.target.value}
        //setUserFromTable({...userFromTable, familyName: event.target.value})
    }
    const handleEmailChange = (event) => {
        setUserFromTable({...userFromTable, email: event.target.value})
    }
    const handleGivenNameChange = (event) => {
        setUserFromTable({...userFromTable, givenName: event.target.value})
    }

    const userStatusMap = [{value: "active"}, {value: "inactive"}]
    const popupAction = () => {
        return (props.isNew) ? ('Ajouter ') : ('Modifier ')
    }
console.log(userProfil)

    return (!loaded) ? (
            <div className="center"><Loader type='TailSpin' color='black' height={100} width={100}/></div>) : (
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="ztoPopUpCont">

                <div className="ztogroupedrow ztoModalTitle">
                    <Row className="popUpRow">
                        <Col className="popupItemPadding" xs="10">{ popupAction()} un utilisateur</Col>
                        <Col className="popupItemPadding closeArrow" xs="2" onClick={ closeModal}>
                            <a onClick={ closeModal}></a>
                            x</Col>
                    </Row>
                </div>
                <div className="ztogroupedrow">
                    <Row className="popUpRow">
                        <Col className="popupItemPadding ztoItemLabel" xs="6">NOM</Col>
                        <Col className="popupItemPadding ztoItemLabel" xs="6">PRENOM</Col>
                    </Row>
                    <Row className="popUpRow">

                        <Col xs="6">
                            <input name="familyName" ref={register({ required: true })} className="popInput"/>
                            {/* errors will return when field validation fails  */}
                            {errors.familyName && <span>Ce champ est obligatoire </span>}
                        </Col>
                        <Col xs="6">
                            <input name="givenName" ref={register({ required: true })} className="popInput"/>
                            {/* errors will return when field validation fails  */}
                            {errors.givenName && <span>Ce champ est obligatoire </span>}
                        </Col>
                    </Row>
                </div>

                <div className="ztogroupedrow">
                    <Row className="popUpRow">
                        <Col className="popupItemPadding ztoItemLabel" xs="6">PDV</Col>
                        <Col className="popupItemPadding ztoItemLabel" xs="6">REGION</Col>
                    </Row>
                    <Row className="popUpRow">

                        <Col xs="6">
                            <input name="pdv" ref={register({ required: false })} className="popInput"/>
                            {/* errors will return when field validation fails  */}
                            {errors.pdv && <span>Ce champ est obligatoire </span>}
                        </Col>
                        <Col xs="6">
                            <input name="region" ref={register({ required: true })} className="popInput"/>
                            {/* errors will return when field validation fails  */}
                            {errors.region && <span>Ce champ est obligatoire </span>}
                        </Col>
                    </Row>
                </div>







                <div className="ztogroupedrow">
                    <Row className="popUpRow">
                        <Col className="popupItemPadding ztoItemLabel" xs="6">MOT DE PASSE</Col>
                        <Col className="popupItemPadding ztoItemLabel" xs="6">CONFIRMATION</Col>
                    </Row>
                    <Row className="popUpRow">

                        <Col xs="6">
                            <input type="password" name="password" ref={register({ required: true })} className="popInput"/>
                            {/* errors will return when field validation fails  */}
                            {errors.password && <span>Ce champ est obligatoire </span>}
                        </Col>
                        <Col xs="6">
                            <input type="password" className="popInput" name="passwordconfirm" ref={register({
                                validate: (value) => value === watch('password') || "Passwords don't match."
                            })}  required/>
                            {/* errors will return when field validation fails  */}
                            {errors.passwordconfirm && <span>Les mots de passe ne correspondent pas </span>}
                        </Col>
                    </Row>
                </div>
                <div className="ztogroupedrow">
                    <Row className="popUpRow">
                        <Col className="popupItemPadding ztoItemLabel" xs="12">EMAIL</Col>
                    </Row>
                    <Row className="popUpRow">

                        <Col xs="6">
                            <input name="email" ref={register({ required: true })} className="popInput"/>
                            {/* errors will return when field validation fails  */}
                            {errors.email && <span>Ce champ est obligatoire </span>}
                        </Col>
                    </Row>
                </div>

                <div className="ztogroupedrow">
                    <Row className="popUpRow">
                        <Col xs="12" className="ztoItemLabel">PROFIL</Col>
                    </Row>
                    <Row className="popUpRow">
                        <Col xs="6">
                            <button type="button" className={userProfil.profil == 1 ? 'popupButton' : 'popupButtonInactive'}
                                    onClick={changeUserProfil}
                            >POINT DE VENTE
                            </button>
                        </Col>
                        <Col xs="6">
                            <button  type="button"  className={userProfil.profil == 2 ? 'popupButton' : 'popupButtonInactive'}
                                     onClick={changeUserProfil}
                            >ADMIN REGION
                            </button>
                        </Col>

                    </Row>
                </div>





                <div className="ztogroupedrow">
                    <Row className="popUpRow">
                        <Col xs="12" className="ztoItemLabel">TYPE DE COMPTE</Col>
                    </Row>
                    <Row className="popUpRow">
                        <Col xs="6">
                            <button type="button" className={userRole.role == "user" ? 'popupButton' : 'popupButtonInactive'}
                                    onClick={changeUserRole}
                            >UTILISATEUR
                            </button>
                        </Col>
                        <Col xs="6">
                            <button  type="button"  className={userRole.role == "admin" ? 'popupButton' : 'popupButtonInactive'}
                                    onClick={changeUserRole}
                            >ADMIN
                            </button>
                        </Col>

                    </Row>
                </div>


                {submitErrorContainer()}

                <Row className="ztoactionButton popUpRow">
                    <Col xs="12">
                        <Button variant="primary" type="submit">{popupAction()} utilisateur</Button>
                        {'   '}
                        <Button variant="danger" onClick={ closeModal}>Fermer</Button>
                    </Col>
                </Row>

            </div>
            </form>
        )


}

function mapStateToProps(state) {
    console.log(state)
    return {
        usercreated: state.admin.usercreated

    };
}

export default connect(mapStateToProps)(UserForm);